<template>
    <div id="feedbackDrewer">
        <el-drawer
            title=""
            size="560px"
            :visible.sync="drawerChidren"
            :direction="direction"
            @close="closeDrewerChidren"
        >
            <div class="feedback-drewer-content">
                <div class="row-cell-box">
                    <div class="base-info">
                        <div class="row-cell">
                            <span>提交人：</span>
                            <span>{{ info.userName }}</span>
                        </div>
                        <div class="row-cell">
                            <span>渠道：</span>
                            <span>{{
                                info.platform == 1 ? "支付宝" : "微信"
                            }}</span>
                        </div>
                        <div class="row-cell">
                            <span>提交时间：</span>
                            <span>{{
                                fmDate(new Date(info.createTime).getTime())
                            }}</span>
                        </div>
                        <div class="row-cell">
                            <span>联系电话：</span>
                            <span>{{ info.mobile }}</span>
                        </div>
                    </div>
                    <div class="col-cell">
                        <div class="col-cell-title">问题和意见</div>
                        <div class="col-cell-content">
                            <div class="col-cell-content">
                                {{ info.feedback.infos[0].content }}
                            </div>
                            <div
                                v-for="(mediaCell, index) in info.feedback
                                    .infos[0].mediaInfos || []"
                                :key="index"
                                class="image-box"
                            >
                                <el-image
                                    v-if="mediaCell.type == 'IMAGE'"
                                    style="width: 400px"
                                    :src="mediaCell.url"
                                    :preview-src-list="[mediaCell.url]"
                                >
                                </el-image>
                                <video
                                    v-if="mediaCell.type == 'VIDEO'"
                                    :src="mediaCell.url"
                                    controls="controls"
                                ></video>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(cell, index) in info.feedback.infos"
                        :key="index"
                        class="more-message"
                    >
                        <div v-if="index != 0" class="more-message-cell">
                            <div class="user-info">
                                <img
                                    v-if="cell.type == 'RESPONDER'"
                                    style="width: 20px; height: 20px"
                                    src="../../assets/images/logo.png"
                                    alt=""
                                    srcset=""
                                />
                                <span class="user-info-name">{{
                                    cell.userName
                                }}</span>
                                <span class="user-info-time"
                                    >{{ fmDate(cell.messageTime) }} 回复</span
                                >
                            </div>
                            <div class="message-content">
                                <div
                                    v-if="
                                        cell.content && cell.content.length != 0
                                    "
                                    class="message-text"
                                >
                                    {{ cell.content }}
                                </div>
                                <div
                                    v-for="(
                                        mediaCell, index
                                    ) in cell.mediaInfos || []"
                                    :key="index"
                                    class="image-box"
                                >
                                    <el-image
                                        v-if="mediaCell.type == 'IMAGE'"
                                        style="width: 400px"
                                        :src="mediaCell.url"
                                        :preview-src-list="[mediaCell.url]"
                                    >
                                    </el-image>
                                    <video
                                        width="400"
                                        v-if="mediaCell.type == 'VIDEO'"
                                        :src="mediaCell.url"
                                        controls="controls"
                                    ></video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(cell, index) in newFeedbackInfos"
                        :key="'key' + index"
                        class="more-message"
                    >
                        <div class="more-message-cell">
                            <div class="user-info">
                                <img
                                    v-if="cell.type == 'RESPONDER'"
                                    style="width: 20px; height: 20px"
                                    src="../../assets/images/logo.png"
                                    alt=""
                                    srcset=""
                                />
                                <span class="user-info-name">{{
                                    cell.userName
                                }}</span>
                                <span class="user-info-time"
                                    >{{ fmDate(cell.messageTime) }} 回复</span
                                >
                            </div>
                            <div class="message-content">
                                <div
                                    v-if="
                                        cell.content && cell.content.length != 0
                                    "
                                    class="message-text"
                                >
                                    {{ cell.content }}
                                </div>
                                <div
                                    v-for="(
                                        mediaCell, index
                                    ) in cell.mediaInfos || []"
                                    :key="index"
                                    class="image-box"
                                >
                                    <el-image
                                        v-if="mediaCell.type == 'IMAGE'"
                                        style="width: 400px"
                                        :src="mediaCell.url"
                                        :preview-src-list="[mediaCell.url]"
                                    >
                                    </el-image>
                                    <video
                                        width="400"
                                        v-if="mediaCell.type == 'VIDEO'"
                                        :src="mediaCell.url"
                                        controls="controls"
                                    ></video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-space" id="bottom-space"></div>
                </div>
                <div class="input-box">
                    <el-input
                        placeholder="请输入内容"
                        v-model="inputValue"
                        type="textarea"
                        rows="4"
                        :autosize="autosize"
                    >
                    </el-input>

                    <div class="reply-box">
                        <el-upload
                            class="upload-demo"
                            ref="upload"
                            :action="importFileUrl"
                            :file-list="fileList"
                            :before-upload="beforeUpload"
                            :show-file-list="false"
                            :on-progress="uploadprogress"
                            :on-success="
                                (res, file) => {
                                    uploadSuccess(res, file);
                                }
                            "
                            :on-error="uploadErr"
                            :auto-upload="true"
                        >
                            <el-button
                                v-show="!uploading"
                                slot="trigger"
                                type="text"
                                size="small"
                                >发送图片/视频</el-button
                            >
                            <el-button
                                v-show="uploading"
                                type="text"
                                :loading="uploading"
                                >上传中</el-button
                            >
                        </el-upload>

                        <el-button @click="reply" size="mini" type="primary"
                            >回 复</el-button
                        >
                    </div>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    props: ["drawer", "info"],
    data() {
        return {
            importFileUrl: this.requestUrl.feedback.feedbackUpload,
            direction: "rtl",
            autosize: false,
            inputValue: "",
            fileList: [],
            newFeedbackInfos: [],
            uploading: false,
            isRely:false, // 是否回复过
        };
    },
    methods: {
        uploadSuccess(res, file) {
            console.log(res, file, file.raw);
            this.reply("media", {
                type:
                    file.raw.type.split("/")[0] == "image" ? "IMAGE" : "VIDEO",
                url: file.response.data,
            });
            this.isRely = true
            this.uploading = false;
        },
        uploadErr() {
            this.$message.error("文件上传失败！");
            this.uploading = false;
        },
        uploadprogress() {
            this.uploading = true;
        },
        fmDate(datetimes) {
            let date = new Date(datetimes);
            let Y = date.getFullYear() + "-";
            let M =
                (date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1) + "-";
            let D =
                (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
                " ";
            let h =
                (date.getHours() > 9
                    ? date.getHours()
                    : "0" + date.getHours()) + ":";
            let m =
                (date.getMinutes() > 9
                    ? date.getMinutes()
                    : "0" + date.getMinutes()) + ":";
            let s =
                date.getSeconds() > 9
                    ? date.getSeconds()
                    : "0" + date.getSeconds();
            //      年 月 日 时 分 秒
            return Y + M + D + h + m + s;
        },
        closeDrewerChidren() {
            this.isRely = false; // 重置false
            this.newFeedbackInfos = [];
            this.drawerChidren = false;
        },
        submitUpload() {
            this.$refs.upload.submit();
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        beforeUpload(file) {
            console.log(file);
            const isJPGOrVideo =
                file.type === "image/JPG" ||
                file.type === "image/JPEG" ||
                file.type === "image/PNG" ||
                file.type === "image/jpeg" ||
                file.type === "image/jpg" ||
                file.type === "image/png" ||
                file.type === "image/GIF" ||
                file.type === "image/gif" ||
                file.type === "video/mp4" ||
                file.type === "video/ogg" ||
                file.type === "video/flv" ||
                file.type === "video/avi" ||
                file.type === "video/wmv" ||
                file.type === "video/rmvb" ||
                file.type === "video/mov" ||
                file.type === "video/quicktime";
            // const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPGOrVideo) {
                this.$message.error("请上传图或者视频文件！");
            }
            // if (!isLt2M) {
            //     this.$message.error(
            //         "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
            //     );
            // }
            // return isJPGOrideo && isLt2M;
            return isJPGOrVideo;
        },
        handlePreview(file) {
            console.log(file);
        },
        reply(type, meidaInfo) {
            console.log(type, meidaInfo);
            let param = {
                content: this.inputValue,
                feedbackId: this.info.id,
                mobile: this.info.mobile,
                openId: this.info.openId,
                platform: this.info.platform,
            };
            if (type == "media") {
                param.mediaInfos = [meidaInfo];
            }
            let flag = false;
            if (this.inputValue.trim() != "") {
                flag = true;
            } else {
                if (meidaInfo) {
                    flag = true;
                }
            }
            flag &&
                this.FetchPost(
                    this.requestUrl.feedback.feedbackReply + this.info.id,
                    param
                )
                    .then((res) => {
                        if (res.errno === "0") {
                            this.newFeedbackInfos.push(
                                res.data.feedback.infos.pop()
                            );
                            this.inputValue = "";
                            this.isRely = true;
                            this.newFeedbackInfos = [...this.newFeedbackInfos];
                            this.$nextTick(() => {
                                let container = this.$el.querySelector(
                                    ".row-cell-box"
                                );
                                container.scrollTop = container.scrollHeight;
                                setTimeout(() => {
                                    document
                                        .getElementById("bottom-space")
                                        .scrollIntoView();
                                }, 1500);
                            });
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
        },
    },
    computed: {
        // 子组件中更改绑定的prop值最好使用计算属性computed解决
        drawerChidren: {
            get() {
                return this.drawer;
            },

            set() {
                // introductionList_改变由父组件控制
                console.log(this.direction);
                this.$emit("closedrewer", this.isRely);
            },
        },
    },
};
</script>

<style lang="less">
.el-image-viewer__wrapper {
    z-index: 9999999999999999 !important;
}
.feedback-drewer-content {
    height: calc(100vh - 77px);
    .row-cell-box {
        height: calc(100vh - 280px);
        flex: none;
        overflow: auto;
        padding: 0 16px;
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        // flex-wrap: wrap;
        .base-info {
            .row-cell {
                display: inline-block;
                width: 240px;
                margin: 8px;
            }
        }

        .col-cell {
            width: 100%;
            flex: none;
            margin: 8px;
            .col-cell-title {
                margin-bottom: 8px;
            }
            .image-box {
                text-align: center;
                margin-top: 16px;
            }
        }
        .more-message {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            .more-message-cell {
                .user-info {
                    line-height: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: flex-start;
                    .user-info-name {
                        margin: 0 8px;
                        color: grey;
                    }
                    .user-info-time {
                        line-height: 20px;
                        height: 20px;
                        font-size: 12px;
                        color: grey;
                    }
                }
                .message-content {
                    margin: 16px 0;
                }
            }
        }
    }
    .input-box {
        padding: 16px;
        height: 148px !important;
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .el-input {
            flex: none;
            height: 100px;
            width: 100%;
        }
        .reply-box {
            height: 36px;
            flex: none;
            display: flex;
            justify-content: flex-end;
            .el-button {
                margin-left: 16px;
            }
        }
    }
}
</style>