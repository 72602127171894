<template>
  <el-container>
    <el-aside width="64px">
      <LeftMenu></LeftMenu>
    </el-aside>
    <el-container v-show="visibleType == 'list'">
      <el-header>
        <div class="header-title">邮件监控</div>
        <div class="serach-box">
          <el-input
            placeholder="可搜索发件人，邮件主题，收件人"
            v-model="searchValue"
            class="input-with-select"
            @keyup.enter.native="search"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="search"
            ></el-button>
          </el-input>
        </div>
      </el-header>

      <el-main>
        <div id="fapiao-monitor">
          <el-tabs
            v-model="tabsValue"
            @tab-click="switchTab"
            tab-position="left"
          >
            <el-tab-pane name="unhandled">
                            <span slot="label"
                            >未处理（{{ cusCount.unhandledCount }}）</span
                            >
              <div class="table-box">
                <div class="table-content">
                  <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    height="calc(100vh - 140px)"
                    :default-sort="{
                                            prop: 'updateTime',
                                            order: 'descending',
                                        }"
                    @sort-change="sortChange"
                    @selection-change="
                                            handleSelectionChange
                                        "
                  >
                    <el-table-column
                      type="selection"
                      width="55"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="sender"
                      label="发件人"
                      width="220"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="subject"
                      label="主题"
                      show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                      prop="updateTime"
                      label="时间"
                      sortable
                      width="180"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="email"
                      label="收件人"
                      width="220"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="confidence"
                      label="可信度(%)"
                      sortable
                      width="110"
                    >
                    </el-table-column>
                    <el-table-column
                      label="操作"
                      width="120"
                    >
                      <template slot-scope="scope">
                        <el-button
                          @click.native.prevent="
                                                        switchEmailVisible(
                                                            'detail',
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                          type="text"
                          size="small"
                        >
                          查看
                        </el-button>
                        <el-button
                          type="text"
                          size="small"
                          @click.native.prevent="
                                                        ignore(
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                        >
                          忽略
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="table-box-footer">
                  <div class="footer-operation">
                    <el-button
                      type="primary"
                      size="small"
                      @click="ignoreB"
                      :disabled="
                                                this.multipleSelection.length ==
                                                0
                                            "
                      class="export-fapiao"
                    >忽略选中
                    </el-button>
                    <el-button
                      type="default"
                      size="small"
                      @click="handleException"
                      :disabled="
                                                this.multipleSelection.length ==
                                                0
                                            "
                      class="export-fapiao"
                    >发票异常
                    </el-button>
                  </div>
                  <el-pagination
                    background
                    layout="total, prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="ignored">
                            <span slot="label"
                            >已忽略（{{ cusCount.ignoredCount }}）</span
                            >
              <div class="table-box">
                <div class="table-content">
                  <el-table
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    height="calc(100vh - 140px)"
                    :default-sort="{
                                            prop: 'updateTime',
                                            order: 'descending',
                                        }"
                    @sort-change="sortChange"
                  >
                    <el-table-column
                      prop="sender"
                      label="发件人"
                      width="320"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="subject"
                      label="主题"
                      show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                      prop="updateTime"
                      label="时间"
                      sortable
                      width="180"
                    >
                      <template slot-scope="scope">
                        <div>
                          {{
                          fmDate(
                          scope.row
                          .updateTime
                          )
                          }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="email"
                      label="收件人"
                      width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      label="操作"
                      width="100"
                    >
                      <template slot-scope="scope">
                        <el-button
                          @click.native.prevent="
                                                        switchEmailVisible(
                                                            'detail',
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                          type="text"
                          size="small"
                        >
                          查看
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="table-box-footer">
                  <div class="footer-operation"></div>
                  <el-pagination
                    background
                    layout="total, prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="exception">
                            <span slot="label"
                            >发票异常（{{ cusCount.signExceptionCount }}）</span
                            >
              <div class="table-box">
                <div class="table-content">
                  <el-table
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    height="calc(100vh - 140px)"
                    :default-sort="{
                                            prop: 'updateTime',
                                            order: 'descending',
                                        }"
                    @sort-change="sortChange"
                  >
                    <el-table-column
                      prop="sender"
                      label="发件人"
                      width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="subject"
                      label="主题"
                      show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                      prop="updateTime"
                      label="处理时间"
                      sortable
                      width="180"
                    >
                      <template slot-scope="scope">
                        <div>
                          {{ fmDate(scope.row.updateTime) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="handlerName"
                      label="处理人"
                      width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="email"
                      label="收件人"
                      width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      label="操作"
                      width="120"
                    >
                      <template slot-scope="scope">
                        <el-button
                          @click.native.prevent="
                                                        switchEmailVisible(
                                                            'detail',
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                          type="text"
                          size="small"
                        >
                          查看
                        </el-button>
                        <el-button
                          @click.native.prevent="
                                                        resetIgnore(
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                          type="text"
                          size="small"
                        >
                          撤销
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="table-box-footer">
                  <div class="footer-operation"></div>
                  <el-pagination
                    background
                    layout="total, prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane name="handled">
                            <span slot="label"
                            >处理记录（{{ cusCount.handledCount }}）</span
                            >
              <div class="table-box">
                <div class="table-content">
                  <el-table
                    :data="tableData"
                    tooltip-effect="dark"
                    style="width: 100%"
                    height="calc(100vh - 140px)"
                    :default-sort="{
                                            prop: 'updateTime',
                                            order: 'descending',
                                        }"
                    @sort-change="sortChange"
                  >
                    <el-table-column
                      prop="sender"
                      label="发件人"
                      width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="subject"
                      label="主题"
                      show-overflow-tooltip
                    >
                    </el-table-column>
                    <el-table-column
                      prop="updateTime"
                      label="处理时间"
                      sortable
                      width="180"
                    >
                      <template slot-scope="scope">
                        <div>
                          {{ fmDate(scope.row.updateTime) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="handlerName"
                      label="处理人"
                      width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="email"
                      label="收件人"
                      width="120"
                    >
                    </el-table-column>
                    <el-table-column
                      label="操作"
                      width="120"
                    >
                      <template slot-scope="scope">
                        <el-button
                          @click.native.prevent="
                                                        switchEmailVisible(
                                                            'detail',
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                          type="text"
                          size="small"
                        >
                          查看
                        </el-button>
                        <el-button
                          @click.native.prevent="
                                                        resetIgnore(
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                          type="text"
                          size="small"
                        >
                          撤销
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>

                <div class="table-box-footer">
                  <div class="footer-operation"></div>
                  <el-pagination
                    background
                    layout="total, prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-main>
    </el-container>
    <EmailDetail
      @switchEmailVisible="switchEmailVisible"
      @detailReset="detailReset"
      v-show="visibleType != 'list'"
      :emailDetail="emailDetail"
      :tabsValue="tabsValue"
    ></EmailDetail>
  </el-container>
</template>

<script>
  import LeftMenu from "../../components/menu/leftMenu.vue";
  import EmailDetail from "./ShowEmailDetail.vue";

  export default {
    components: {
      LeftMenu,
      EmailDetail,

    },
    data() {
      return {
        cusCount: {
          handledCount: "0",
          ignoredCount: "0",
          unhandledCount: "0",
          signExceptionCount: "0"
        }, // 邮件数量
        tabsValue: "unhandled", // 邮件类型 unhandled ignored handled
        visibleType: "list", // 页面展示列表/详情 list detail
        emailInfo: {}, // 邮件信息
        isIndeterminate: false,
        currentPage: 1,
        pageSize: 20,
        total: 0,
        allPage: false,
        selectFapiaoType: "0",
        searchValue: "",
        tableData: [],
        multipleSelection: [],
        emailDetail: {
          attachments: [],
        },
        orderType: "descending",
        orderBy: "updateTime",
      };
    },
    methods: {
      // 排序修改
      sortChange(obj) {
        this.orderType = obj.order;
        this.orderBy = obj.prop;
        this.getEmailList();
      },
      search() {
        this.getEmailList();
      },
      toggleSelection(rows) {
        if (rows) {
          rows.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 展示list or detail
      switchEmailVisible(visibleType, info) {
        if (visibleType == "detail") {
          this.emailInfo = info;
          this.getEmailDetail(info.id);
        } else {
          this.visibleType = "list";
          this.getEmailList();
          this.getCusCount();
        }
      },
      // 获取邮件详细信息
      getEmailDetail(id) {
        this.FetchGet(this.requestUrl.email.emailToInvoice + "/" + id, {})
          .then((res) => {
            if (res.errno === "0") {
              this.visibleType = "detail";
              this.emailDetail = res.data;
            } else {
              this.$message.error(res.errmsg);
            }
          })
          .catch((err) => {
            this.$message(err);
          });
      },
      // 获取智能邮箱邮件的数量
      getCusCount() {
        this.FetchGet(this.requestUrl.email.cusCount, {})
          .then((res) => {
            if (res.errno === "0") {
              this.cusCount = res.data;
            } else {
              this.$message.error(res.errmsg);
            }
          })
          .catch((err) => {
            this.$message(err);
          });
      },
      // 切换tab邮件类型
      switchTab() {
        this.allPage = false;
        this.orderBy = "updateTime";
        this.currentPage = 1;
        this.multipleSelection = [];
        this.getEmailList();
      },
      // 智能邮箱邮件列表
      getEmailList() {
        let url = "";
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        switch (
          this.tabsValue // unhandled ignored handled
          ) {
          case "unhandled":
            url = this.requestUrl.email.emailUnhandled;
            break;
          case "ignored":
            url = this.requestUrl.email.emailIgnored;
            break;
          case "exception":
            url = this.requestUrl.email.emailException;
            break;
          case "handled":
            url = this.requestUrl.email.emailHandled;
            break;
          default:
            url = this.requestUrl.email.emailUnhandled;
            break;
        }
        this.FetchGet(url, {
          keyword: this.searchValue,
          pageNo: this.currentPage,
          pageSize: this.pageSize,
          orderType: this.orderType == "descending" ? 1 : 0,
          orderBy:
            this.orderBy == "updateTime" ? "updateTime" : "confidence",
        })
          .then((res) => {
            if (res.errno === "0") {
              if (res.data.list != null) {
                this.tableData = [...res.data.list];
              } else {
                this.tableData = [];
              }

              this.total = res.data.total;
            } else {
              this.$message.error(res.errmsg);
            }
            loading.close();
          })
          .catch((err) => {
            loading.close();
            this.$message(err);
          });
      },
      // 分页操作
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.getEmailList();
      },
      // 单挑数据忽略
      ignore(obj) {
        console.log(obj);
        this.FetchPut(this.requestUrl.email.ignore, obj.id)
          .then((res) => {
            if (res.errno === "0") {
              this.getEmailList();
              this.getCusCount();
              this.$message.success("已忽略");
            } else {
              this.$message.error(res.errmsg);
            }
          })
          .catch((err) => {
            this.$message(err);
          });
      },
      // 批量标记发票异常
      handleException() {
        let emailRecordIds = [];
        this.multipleSelection.forEach((item) => {
          emailRecordIds.push(item.id);
        });
        this.FetchPost(this.requestUrl.email.exceptionBatch, emailRecordIds)
          .then((res) => {
            if (res.errno === "0") {
              this.getEmailList();
              this.getCusCount();
              this.$message.success("已标记发票异常");
            } else {
              this.$message.error(res.errmsg);
            }
          })
          .catch((err) => {
            this.$message(err);
          });
      },
      // 批量忽略
      ignoreB() {
        let emailRecordIds = [];
        this.multipleSelection.forEach((item) => {
          emailRecordIds.push(item.id);
        });
        this.FetchPost(this.requestUrl.email.ignorebatch, emailRecordIds)
          .then((res) => {
            if (res.errno === "0") {
              this.getEmailList();
              this.getCusCount();
              this.$message.success("已忽略");
            } else {
              this.$message.error(res.errmsg);
            }
          })
          .catch((err) => {
            this.$message(err);
          });
      },
      //  添加发票后重新获取数据
      detailReset() {
        this.getEmailList();
        this.getCusCount();
      },
      // 撤销
      resetIgnore(value) {
        this.FetchPost(
          this.requestUrl.email.cancel + value.id,
          {}
        )
          .then((res) => {
            if (res.errno === "0") {
              this.getEmailList();
              this.getCusCount();
              this.$message.success("已撤销");
            } else {
              this.$message.error(res.errmsg);
            }
          })
          .catch((err) => {
            this.$message(err);
          });
      },
      fmDate(datetimes) {
        let date = new Date(datetimes);
        let Y = date.getFullYear() + "-";
        let M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        let D =
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          " ";
        let h =
          (date.getHours() > 9
            ? date.getHours()
            : "0" + date.getHours()) + ":";
        let m =
          (date.getMinutes() > 9
            ? date.getMinutes()
            : "0" + date.getMinutes()) + ":";
        let s =
          date.getSeconds() > 9
            ? date.getSeconds()
            : "0" + date.getSeconds();
        //      年 月 日 时 分 秒
        return Y + M + D + h + m + s;
      },
    },
    mounted() {
      // 邮件总数
      this.getCusCount();
      // 邮件列表
      this.getEmailList();
    },
  };
</script>

<style lang="less" scoped>
  @import "./../../assets/less/email/email.less";
</style>
