<template>
    <el-container>
        <el-aside width="64px"><LeftMenu></LeftMenu></el-aside>
        <el-container>
            <el-header>
                <div class="header-title">意见反馈</div>
                <div class="condition">
                    <el-tag
                        size="mini"
                        closable
                        v-if="tagSearchBoxInfo.searchValue.trim() != ''"
                        @close="closeCondition('searchValue')"
                    >
                        问题和意见：{{ tagSearchBoxInfo.searchValue.trim() }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="tagSearchBoxInfo.platform.length != 0"
                        @close="closeCondition('platform')"
                    >
                        平台：{{ platformString(tagSearchBoxInfo.platform) }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="tagSearchBoxInfo.createTimeRange.length > 0"
                        @close="closeCondition('createTimeRange')"
                    >
                        入库时间：{{
                            importTimeRangeConfi(
                                tagSearchBoxInfo.createTimeRange
                            )
                        }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="tagSearchBoxInfo.phone.trim() != ''"
                        @close="closeCondition('phone')"
                    >
                        电话：{{ tagSearchBoxInfo.phone.trim() }}
                    </el-tag>
                </div>
                <div class="serach-box">
                    <el-button type="text" @click="switchDropdown()"
                        >高级筛选</el-button
                    >
                </div>
            </el-header>
            <div class="dropdown-box-feedback" v-show="isShowDropdownBox">
                <div class="search-content">
                    <div class="dropdown-box-left">
                        <div class="form-item">
                            <div class="form-item-label">问题和意见</div>
                            <div class="form-item-content">
                                <el-input
                                    size="small"
                                    class="fapiao-amount"
                                    v-model="searchBoxInfo.searchValue"
                                    placeholder="问题和意见"
                                ></el-input>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">渠道</div>
                            <div class="form-item-content">
                                <el-select
                                    v-model="searchBoxInfo.platform"
                                    size="small"
                                    multiple
                                    placeholder="请选择渠道"
                                >
                                    <el-option
                                        v-for="item in [
                                            { name: '微信', id: 0 },
                                            { name: '支付宝', id: 1 },
                                        ]"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">提交时间</div>
                            <div class="form-item-content">
                                <el-date-picker
                                    v-model="searchBoxInfo.createTimeRange"
                                    size="small"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">联系电话</div>
                            <div class="form-item-content">
                                <el-input
                                    size="small"
                                    type="number"
                                    class="fapiao-amount"
                                    v-model="searchBoxInfo.phone"
                                    @input="phoneChange"
                                    placeholder="联系电话"
                                ></el-input>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">查看状态</div>
                            <div class="form-item-content">
                                <el-select
                                    v-model="searchBoxInfo.adminStatus"
                                    size="small"
                                    multiple
                                    placeholder="请选择查看状态"
                                >
                                    <el-option
                                        v-for="item in [
                                            { name: '未读', id: 0 },
                                            { name: '已读', id: 1 },
                                        ]"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">回复状态</div>
                            <div class="form-item-content">
                                <el-select
                                    v-model="searchBoxInfo.replyStatus"
                                    size="small"
                                    multiple
                                    placeholder="请选择查看状态"
                                >
                                    <el-option
                                        v-for="item in [
                                            { name: '已回复', id: 1 },
                                            { name: '未回复', id: 0 },
                                        ]"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dropdown-box-handle">
                    <el-button
                        @click="handleSearch('reset')"
                        size="mini"
                        type="primary"
                        plain
                        round
                        >重置</el-button
                    >
                    <el-button
                        @click="handleSearch('close')"
                        size="mini"
                        plain
                        round
                        >取消</el-button
                    >
                    <el-button
                        @click="handleSearch('submit')"
                        size="mini"
                        type="primary"
                        round
                        >筛选</el-button
                    >
                </div>
            </div>
            <el-main>
                <div id="feedback">
                    <div class="table-box">
                        <div class="table-content">
                            <el-table
                                :data="tableData"
                                tooltip-effect="dark"
                                style="width: 100%"
                                height="calc(100vh - 140px)"
                                :default-sort="{
                                    prop: 'createTime',
                                    order: 'descending',
                                }"
                                @sort-change="sortChange"
                            >
                                <el-table-column
                                    prop="feedback"
                                    label="问题和意见"
                                    show-overflow-tooltip
                                >
                                    <template slot-scope="scope">
                                        <div class="feedback-content">
                                            <div
                                                style="
                                                    display: inline-block;
                                                    line-height: 30px;
                                                    margin-right: 16px;
                                                "
                                            >
                                                {{
                                                    scope.row.feedback.infos[0]
                                                        .content
                                                }}
                                            </div>
                                            <span
                                                style="
                                                    color: #d0aa70;
                                                    font-size: 14px;
                                                "
                                                class="icon-pic el-icon-picture"
                                                v-for="(item, index) in scope
                                                    .row.feedback.infos[0]
                                                    .images || []"
                                                :key="index"
                                            ></span>
                                        </div>
                                    </template>
                                </el-table-column>
                                 <el-table-column
                                    prop="replyStatus"
                                    label="回复状态"
                                    width="100"
                                >
                                    <template slot-scope="scope">
                                        {{
                                            scope.row.replyStatus == 1
                                                ? "已回复"
                                                : "未回复"
                                        }}
                                    </template>
                                </el-table-column>
                                
                                <el-table-column
                                    prop="platform"
                                    label="渠道"
                                    width="100"
                                >
                                    <template slot-scope="scope">
                                        {{
                                            scope.row.platform == 0
                                                ? "微信"
                                                : "支付宝"
                                        }}
                                    </template>
                                </el-table-column>

                                <el-table-column
                                    prop="createTimeShow"
                                    label="提交时间"
                                    sortable
                                    width="180"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="mobile"
                                    label="联系电话"
                                    width="120"
                                >
                                </el-table-column>
                                <el-table-column
                                    fixed="right"
                                    label="操作"
                                    width="60"
                                >
                                    <template slot-scope="scope">
                                        <el-button
                                            @click.native.prevent="
                                                getFeedbackDetail(
                                                    tableData[scope.$index]
                                                )
                                            "
                                            type="text"
                                            size="small"
                                        >
                                            查看
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>

                        <div class="table-box-footer">
                            <el-pagination
                                layout="total, prev, pager, next"
                                :total="total"
                                :page-size="pageSize"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage"
                            >
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
        <DetailDrewer
            :drawer="drawer"
            :info="info"
            @closedrewer="closedrewer"
        ></DetailDrewer>
    </el-container>
</template>

<script>
import LeftMenu from "../../components/menu/leftMenu.vue";
import DetailDrewer from "./DetailDrewer.vue";

export default {
    components: {
        LeftMenu,
        DetailDrewer,
    },
    data() {
        return {
            drawer: false,
            total: 0,
            pageSize: 20,
            currentPage: 1,
            tableData: [],
            info: {
                feedback: { infos: [{ content: "" }] },
            },
            orderType: "descending",
            orderBy: "createTime",
            filterFlag: true,
            platform: [0, 1],
            isShowDropdownBox: false,
            searchBoxInfo: {
                createTimeRange: [],
                searchValue: "",
                platform: [],
                adminStatus: [],
                replyStatus:[],
                phone:""
            },
            tagSearchBoxInfo: {
                createTimeRange: [],
                searchValue: "",
                platform: [],
                adminStatus: [],
                replyStatus:[],
                phone: ""
            },
        };
    },
    methods: {
        phoneChange(value){
            if (value.length > 11) {
                this.searchBoxInfo.phone = value.substring(0,11);
            }
        },
        // 高级筛选
        switchDropdown() {
            this.isShowDropdownBox = !this.isShowDropdownBox;
            this.searchBoxInfo = JSON.parse(
                JSON.stringify(this.tagSearchBoxInfo)
            );
        },
        // 高级筛选操作按钮 重置 关闭 提交
        handleSearch(type) {
            if (type == "reset") {
                this.searchBoxInfo = {
                    createTimeRange: [],
                    searchValue: "",
                    platform: [],
                    adminStatus: [],
                    replyStatus:[],
                    phone: ""
                };
                this.tagSearchBoxInfo = {
                    createTimeRange: [],
                    searchValue: "",
                    platform: [],
                    adminStatus: [],
                    replyStatus:[],
                    phone: ""
                };

                this.getFeedbackList();
            }
            if (type == "close") {
                this.isShowDropdownBox = false;
            }
            if (type == "submit") {
                this.isShowDropdownBox = false;
                this.tagSearchBoxInfo = JSON.parse(
                    JSON.stringify(this.searchBoxInfo)
                );
                this.getFeedbackList();
            }
        },
        // 条件编辑
        closeCondition(type) {
            switch (type) {
                case "searchValue":
                    this.tagSearchBoxInfo.searchValue = "";
                    break;
                case "platform":
                    this.tagSearchBoxInfo.platform = [];
                    break;
                case "adminStatus":
                    this.tagSearchBoxInfo.adminStatus = [];
                    break;
                case "createTimeRange":
                    this.tagSearchBoxInfo.createTimeRange = [];
                    break;
                case "phone":
                    this.tagSearchBoxInfo.phone = "";
                    break;
                default:
                    break;
            }
            this.getFeedbackList();
        },
        // 条件展示
        platformString(arr) {
            let str = "";
            arr.forEach((item, index) => {
                if (item == 0) {
                    if (index != 0) {
                        str += "、微信";
                    } else {
                        str += "微信";
                    }
                } else {
                    if (index != 0) {
                        str += "、支付宝";
                    } else {
                        str += "支付宝";
                    }
                }
            });
            return str;
        },
        importTimeRangeConfi(importTimeRange) {
            let start = new Date(importTimeRange[0]).getTime();
            let end = new Date(importTimeRange[1]).getTime();
            let str = "";
            if (start == end) {
                str = this.changeDate(start, "date");
            } else {
                str =
                    this.changeDate(start, "date") +
                    "~" +
                    this.changeDate(end, "date");
            }
            return str;
        },
        changeDate(datetimes, type) {
            let date = new Date(datetimes);
            let Y = date.getFullYear() + "-";
            let M =
                (date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1) + "-";
            let D =
                (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
                " ";
            let h =
                (date.getHours() > 9
                    ? date.getHours()
                    : "0" + date.getHours()) + ":";
            let m =
                (date.getMinutes() > 9
                    ? date.getMinutes()
                    : "0" + date.getMinutes()) + ":";
            let s =
                date.getSeconds() > 9
                    ? date.getSeconds()
                    : "0" + date.getSeconds();
            //      年 月 日 时 分 秒
            if (type == "time") {
                return Y + M + D + h + m + s;
            } else {
                return Y + M + D;
            }
        },
        // 排序修改
        sortChange(obj) {
            this.orderType = obj.order;
            this.orderBy = obj.prop === 'createTimeShow' ? 'createTime': obj.prop;
            this.getFeedbackList();
        },
        closedrewer(isRely) {
            console.log(isRely);
            const tableData = [...this.tableData];
            if(this.info.replyStatus === 0 && isRely){
               tableData.forEach(item=>{
                    if(item.id === this.info.id){
                        item.replyStatus = 1;
                    }
                })
            }
            this.tableData = tableData;
            this.drawer = false;
        },
        filterTag(value, row, column) {
            if (this.filterFlag) {
                console.log(value, row, column.filteredValue);
                this.filterFlag = false;
                this.platform = column.filteredValue;
                this.getFeedbackList();
                setTimeout(() => {
                    this.filterFlag = true;
                }, 2000);
            }
            return row.confidence === value;
        },
        selectCurrentPage() {},
        selectAllPage(val) {
            console.log(val);
        },
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getFeedbackList();
        },
        // 获取意见反馈列表
        getFeedbackList() {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param = {
                pageNo: this.currentPage,
                pageSize: this.pageSize,
                orderBy: this.orderBy,
                orderType: this.orderType == "descending" ? 1 : 0,
                platform: this.platform,
            };
            if (this.tagSearchBoxInfo.searchValue.trim() != "") {
                param.keyword = this.tagSearchBoxInfo.searchValue.trim();
            }
            if (this.tagSearchBoxInfo.phone.trim() != "") {
                param.mobile = this.tagSearchBoxInfo.phone.trim();
            }
            if (this.tagSearchBoxInfo.platform.length != 0) {
                param.platform = this.tagSearchBoxInfo.platform;
            }
            if (this.tagSearchBoxInfo.adminStatus.length === 1) {
                param.adminStatus = this.tagSearchBoxInfo.adminStatus[0];
            }
            if (this.tagSearchBoxInfo.replyStatus.length === 1) {
                param.replyStatus = this.tagSearchBoxInfo.replyStatus[0];
            }
            if (this.tagSearchBoxInfo.createTimeRange.length != 0) {
                param.startTime = new Date(
                    this.tagSearchBoxInfo.createTimeRange[0]
                ).getTime();
                param.endTime = new Date(
                    this.tagSearchBoxInfo.createTimeRange[1]
                ).getTime() +  (24 * 60 * 60 * 1000 - 1);
            }
            this.FetchPost(this.requestUrl.feedback.list, param)
                .then((res) => {
                    if (res.errno === "0") {
                        this.tableData = [...res.data.list];
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    loading.close();
                })
                .catch((err) => {
                    this.$message(err);
                    loading.close();
                });
        },
        // 根据ID查询意见反馈信息
        getFeedbackDetail(value) {
            this.drawer = true;
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.FetchGet(this.requestUrl.feedback.detail + value.id, {})
                .then((res) => {
                    if (res.errno === "0") {
                        console.log(res);
                        this.info = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                    loading.close();
                })
                .catch((err) => {
                    this.$message(err);
                    loading.close();
                });
        },
    },
    mounted() {
        // 获取意见反馈列表
        this.getFeedbackList();
    },
};
</script>

<style lang="less" scoped>
@import "./../../assets/less/feedback/feedback.less";
.dropdown-box-feedback {
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 10;
    background-color: #ffffff;
    border: 1px solid #dcdfe6;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .search-content {
        width: 450px !important;
        padding: 20px 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .form-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
            .form-item-label {
                width: 90px;
                text-align: right;
            }
            .form-item-content {
                margin-left: 20px;
                width: 310px;
                display: flex;
                flex-direction: row;
                align-items: center;
                .el-radio {
                    margin-right: 10px !important;
                    .fapiao-amount {
                        width: 90px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .dropdown-box-left {
            width: 420px;
        }
        .dropdown-box-right {
            width: 460px;
        }
    }
    .dropdown-box-handle {
        margin-bottom: 16px;
        padding-right: 16px;
        text-align: right;
    }
}
</style>
