import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import { FetchGet, FetchPost, FetchPut, FetchDelete, FetchDelete2, GetFullUrl } from './axios'
import { requestUrl } from './api/index';
import './assets/element-#D0AA70/index.css'

Vue.use(ElementUI);

Vue.prototype.FetchGet = FetchGet;
Vue.prototype.FetchPost = FetchPost;
Vue.prototype.FetchDelete = FetchDelete;
Vue.prototype.FetchDelete2 = FetchDelete2;
Vue.prototype.FetchPut = FetchPut;
Vue.prototype.GetFullUrl = GetFullUrl;

Vue.prototype.requestUrl = requestUrl;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
