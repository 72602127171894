<template>
    <div class="left-menu">
        <div class="logo">
            <img src="./../../assets/images/logo.png" />
        </div>
        <div class="menu">
            <el-menu
                :default-active="activeMenu"
                close="true"
                :collapse="true"
                background-color="#323334"
                text-color="#fff"
                active-text-color="#ffd04b"
                style="height: calc(100vh - 60px)"
                :router="true"
            >
                <el-menu-item index="/home">
                    <i class="arsenal_icon arsenalhome"></i>
                    <span slot="title">首页</span>
                </el-menu-item>
                <el-menu-item index="/monitor">
                    <i class="arsenal_icon arsenalfapiaoxinxi"></i>
                    <span slot="title">发票监控</span>
                </el-menu-item>
                <el-menu-item index="/email">
                    <i class="arsenal_icon arsenalemail"></i>
                    <span slot="title">邮件监控</span>
                </el-menu-item>
                <el-menu-item index="/feedback">
                    <i class="arsenal_icon arsenalfankuizhongxinoff"></i>
                    <span slot="title">意见反馈</span>
                </el-menu-item>
            </el-menu>
        </div>
        <div class="personal-center">
            <el-dropdown @command="topHandleCommand">
                <span class="el-dropdown-link">
                    <img src="./../../assets/images/user-info.png" />
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeMenu: "/",
        };
    },
    methods: {
        getRoutePath() {
            this.activeMenu = this.$route.path;
        },
        topHandleCommand(command) {
            if (command === "logout") {
                this.FetchDelete2(this.requestUrl.auth.logout, {})
                    .then((res) => {
                        if (res.errno === "0") {
                            this.$router.push({
                                name: "Login",
                            });
                        } else {
                            this.$message.error(res.errmsg);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            }
        },
    },
    mounted() {
        this.getRoutePath();
    },
};
</script>
<style lang="less">
.left-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    .logo {
        height: 60px;
        background-color: #323334;
        padding: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 30px;
            width: 30px;
        }
    }
    .menu {
        height: calc(100vh - 120px);
        .arsenal_icon {
            font-size: 22px;
        }
    }
    .personal-center {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
