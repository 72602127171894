import Vue from 'vue'
import VueRouter from 'vue-router'
// 主页
import Home from './../views/home/Home'
// 登录
import Login from './../views/login/Login'
// 监控
import Monitor from './../views/monitor/Monitor'
// 邮箱监控
import Email from './../views/email/Email'
// 意见反馈
import Feedback from './../views/feedback/Feedback'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: Monitor
  },
  {
    path: '/email',
    name: 'Email',
    component: Email
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,

  },
  {
    path: '/',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  routes
})

export default router
