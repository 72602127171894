import axios from 'axios';
// import Qs from 'qs';

//配置axios 请求时长，及默认路径
console.log(process.env);
if ("production"===process.env.VUE_APP_CONFIG_ENV || "test_production"===process.env.VUE_APP_CONFIG_ENV){

    axios.defaults.baseURL = process.env.API_HOST;
}

//axios response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.message === 'Request failed with status code 403') {
            location.hash = "/";
        } else if(error.response.status==510){
            location.href=error.response.data.message;
        } else {
            return Promise.reject(error)
        }


    }
)

//get请求方法封装
function FetchGet(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            data: params,
        }).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err)
        })
    })
}

//post请求方法封装
function FetchPost(url, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, config).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}

//delete请求方法封装
function FetchDelete(url, params, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.delete(url + "/" + params, data, config).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}

//delete2请求方法封装
function FetchDelete2(url, params, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.delete(url, data, config).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}

//delete请求方法封装 带请求体传参
function FetchDelete3(url, params, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.delete(url + "/" + params, data, config).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}

//put请求方法封装
function FetchPut(url, params, data = {}, config) {
    return new Promise((resolve, reject) => {
        axios.put(url + "/" + params, data, config).then(response => {
            resolve(response.data);
        }, err => {
            reject(err)
        })
    })
}

function GetFullUrl(url){
    return axios.defaults.baseURL+url;
}

export {
    FetchGet,
    FetchPost,
    FetchDelete,
    FetchPut,
    FetchDelete2,
    FetchDelete3,
    GetFullUrl,
}
