var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"feedbackDrewer"}},[_c('el-drawer',{attrs:{"title":"","size":"560px","visible":_vm.drawerChidren,"direction":_vm.direction},on:{"update:visible":function($event){_vm.drawerChidren=$event},"close":_vm.closeDrewerChidren}},[_c('div',{staticClass:"feedback-drewer-content"},[_c('div',{staticClass:"row-cell-box"},[_c('div',{staticClass:"base-info"},[_c('div',{staticClass:"row-cell"},[_c('span',[_vm._v("提交人：")]),_c('span',[_vm._v(_vm._s(_vm.info.userName))])]),_c('div',{staticClass:"row-cell"},[_c('span',[_vm._v("渠道：")]),_c('span',[_vm._v(_vm._s(_vm.info.platform == 1 ? "支付宝" : "微信"))])]),_c('div',{staticClass:"row-cell"},[_c('span',[_vm._v("提交时间：")]),_c('span',[_vm._v(_vm._s(_vm.fmDate(new Date(_vm.info.createTime).getTime())))])]),_c('div',{staticClass:"row-cell"},[_c('span',[_vm._v("联系电话：")]),_c('span',[_vm._v(_vm._s(_vm.info.mobile))])])]),_c('div',{staticClass:"col-cell"},[_c('div',{staticClass:"col-cell-title"},[_vm._v("问题和意见")]),_c('div',{staticClass:"col-cell-content"},[_c('div',{staticClass:"col-cell-content"},[_vm._v(" "+_vm._s(_vm.info.feedback.infos[0].content)+" ")]),_vm._l((_vm.info.feedback
                                .infos[0].mediaInfos || []),function(mediaCell,index){return _c('div',{key:index,staticClass:"image-box"},[(mediaCell.type == 'IMAGE')?_c('el-image',{staticStyle:{"width":"400px"},attrs:{"src":mediaCell.url,"preview-src-list":[mediaCell.url]}}):_vm._e(),(mediaCell.type == 'VIDEO')?_c('video',{attrs:{"src":mediaCell.url,"controls":"controls"}}):_vm._e()],1)})],2)]),_vm._l((_vm.info.feedback.infos),function(cell,index){return _c('div',{key:index,staticClass:"more-message"},[(index != 0)?_c('div',{staticClass:"more-message-cell"},[_c('div',{staticClass:"user-info"},[(cell.type == 'RESPONDER')?_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("../../assets/images/logo.png"),"alt":"","srcset":""}}):_vm._e(),_c('span',{staticClass:"user-info-name"},[_vm._v(_vm._s(cell.userName))]),_c('span',{staticClass:"user-info-time"},[_vm._v(_vm._s(_vm.fmDate(cell.messageTime))+" 回复")])]),_c('div',{staticClass:"message-content"},[(
                                    cell.content && cell.content.length != 0
                                )?_c('div',{staticClass:"message-text"},[_vm._v(" "+_vm._s(cell.content)+" ")]):_vm._e(),_vm._l((cell.mediaInfos || []),function(mediaCell,index){return _c('div',{key:index,staticClass:"image-box"},[(mediaCell.type == 'IMAGE')?_c('el-image',{staticStyle:{"width":"400px"},attrs:{"src":mediaCell.url,"preview-src-list":[mediaCell.url]}}):_vm._e(),(mediaCell.type == 'VIDEO')?_c('video',{attrs:{"width":"400","src":mediaCell.url,"controls":"controls"}}):_vm._e()],1)})],2)]):_vm._e()])}),_vm._l((_vm.newFeedbackInfos),function(cell,index){return _c('div',{key:'key' + index,staticClass:"more-message"},[_c('div',{staticClass:"more-message-cell"},[_c('div',{staticClass:"user-info"},[(cell.type == 'RESPONDER')?_c('img',{staticStyle:{"width":"20px","height":"20px"},attrs:{"src":require("../../assets/images/logo.png"),"alt":"","srcset":""}}):_vm._e(),_c('span',{staticClass:"user-info-name"},[_vm._v(_vm._s(cell.userName))]),_c('span',{staticClass:"user-info-time"},[_vm._v(_vm._s(_vm.fmDate(cell.messageTime))+" 回复")])]),_c('div',{staticClass:"message-content"},[(
                                    cell.content && cell.content.length != 0
                                )?_c('div',{staticClass:"message-text"},[_vm._v(" "+_vm._s(cell.content)+" ")]):_vm._e(),_vm._l((cell.mediaInfos || []),function(mediaCell,index){return _c('div',{key:index,staticClass:"image-box"},[(mediaCell.type == 'IMAGE')?_c('el-image',{staticStyle:{"width":"400px"},attrs:{"src":mediaCell.url,"preview-src-list":[mediaCell.url]}}):_vm._e(),(mediaCell.type == 'VIDEO')?_c('video',{attrs:{"width":"400","src":mediaCell.url,"controls":"controls"}}):_vm._e()],1)})],2)])])}),_c('div',{staticClass:"bottom-space",attrs:{"id":"bottom-space"}})],2),_c('div',{staticClass:"input-box"},[_c('el-input',{attrs:{"placeholder":"请输入内容","type":"textarea","rows":"4","autosize":_vm.autosize},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),_c('div',{staticClass:"reply-box"},[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"action":_vm.importFileUrl,"file-list":_vm.fileList,"before-upload":_vm.beforeUpload,"show-file-list":false,"on-progress":_vm.uploadprogress,"on-success":function (res, file) {
                                _vm.uploadSuccess(res, file);
                            },"on-error":_vm.uploadErr,"auto-upload":true}},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uploading),expression:"!uploading"}],attrs:{"slot":"trigger","type":"text","size":"small"},slot:"trigger"},[_vm._v("发送图片/视频")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploading),expression:"uploading"}],attrs:{"type":"text","loading":_vm.uploading}},[_vm._v("上传中")])],1),_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":_vm.reply}},[_vm._v("回 复")])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }