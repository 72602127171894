<template>
  <el-container class="email-detail">
    <el-header>
      <div class="header-title" @click="showEmailList">
        <i class="el-icon-back"></i> 查看邮件
      </div>
      <el-button
        v-if="tabsValue === 'unhandled'"
        type="default"
        size="small"
        class="export-fapiao"
        :disabled="isException"
        @click="handleException"
      >发票异常
      </el-button>
    </el-header>

    <el-main>
      <div class="email-detail-content">
        <div class="email-content">
          <div class="email-content-header">
            <div class="subject">{{ emailDetail.subject }}</div>
            <div class="email-content-send">
                            <span
                            >发件人：【{{ emailDetail.sender }}】于
                                {{ changeDate(emailDetail.receiveTime, 'time') }}
                                发送</span
                            >
            </div>
          </div>
          <div
            v-html="emailDetail.htmlContent"
            class="email-content-html"
          ></div>
          <div
            v-if="emailDetail.attachments"
            class="email-content-attachments"
          >
            <div class="email-content-attachments-header">
              <span>📎</span>
              <span
              >附件（{{
                                    emailDetail.attachments &&
                                    emailDetail.attachments.length
                                }}）</span
              >
              <span
                v-if="
                                    false &&
                                    emailDetail.attachments &&
                                    emailDetail.attachments.length != 0
                                "
                class="downloadAtt"
                @click="downloadAtt(emailDetail.attachments)"
              >
                                全部下载
                            </span>
            </div>
            <div class="attachments-list">
              <div
                v-for="(item, index) in emailDetail.attachments"
                :key="index"
                class="attachments-cell"
              >
                <div class="attachments-icon"></div>
                <div class="attachments-cell-content">
                  <div class="attachments-cell-content-name">
                    {{ item.split("/").pop() }}
                  </div>
                  <div
                    class="attachments-cell-content-handle"
                  >
                    <el-button
                      v-if="
                                                item
                                                    .split('/')
                                                    .pop()
                                                    .split('.')
                                                    .pop() == 'pdf'
                                            "
                      type="text"
                    >
                      <a target="blank" :href="item"
                      >预览</a
                      >
                    </el-button>
                    <el-button
                      @click="downloadAtt([item])"
                      type="text"
                    >
                      下载
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="create-invoice" v-if="tabsValue !== 'exception'">
          <div class="list-box">
            <div
              v-if="tabsValue == 'handled' || isCreated"
              class="invoice-list"
            >
              <div
                v-for="(
                                    cell, index
                                ) in emailDetail.invoiceBaseInfos"
                :key="index"
                class="invoice-cell"
              >
                <div class="create-cell">
                  <div class="create-cell-title">邮箱</div>
                  <div class="create-cell-content">
                    {{ emailDetail.receiver }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    发票代码
                  </div>
                  <div class="create-cell-content">
                    {{ cell.invoiceCode }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    发票校验码
                  </div>
                  <div class="create-cell-content">
                    {{ cell.invoiceCheckCode }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    发票号码
                  </div>
                  <div class="create-cell-content">
                    {{ cell.invoiceNo }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    开票日期
                  </div>
                  <div class="create-cell-content">
                    {{ changeDate(cell.invoiceDate, 'date') }}
                  </div>
                </div>
                <div v-if="false" class="create-cell">
                  <div class="create-cell-title">
                    开票金额（不含税）
                  </div>
                  <div class="create-cell-content">
                    {{ cell.invoiceAmount }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    发票PDF
                  </div>
                  <div class="create-cell-content">
                    <a target="blank" :href="cell.invoicePaths[0]">{{cell.invoicePaths[0].split('/').pop()}}</a>
                  </div>
                </div>
              </div>

            </div>
            <div v-if="tabsValue == 'handled' ||  isCreated"
                 class="invoice-list">
              <div
                v-for="(
                                    cell, index
                                ) in invoiceBaseInfos"
                :key="index"
                class="invoice-cell"
              >
                <div class="create-cell">
                  <div class="create-cell-title">邮箱</div>
                  <div class="create-cell-content">
                    {{ emailDetail.receiver }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    发票代码
                  </div>
                  <div class="create-cell-content">
                    {{ cell.invoiceCode }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    发票校验码
                  </div>
                  <div class="create-cell-content">
                    {{ cell.invoiceCheckCode }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    发票号码
                  </div>
                  <div class="create-cell-content">
                    {{ cell.invoiceNo }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    开票日期
                  </div>
                  <div class="create-cell-content">
                    {{ changeDate(cell.invoiceDate, 'date') }}
                  </div>
                </div>
                <div v-if="false" class="create-cell">
                  <div class="create-cell-title">
                    开票金额（不含税）
                  </div>
                  <div class="create-cell-content">
                    {{ cell.invoiceAmount }}
                  </div>
                </div>
                <div class="create-cell">
                  <div class="create-cell-title">
                    发票PDF
                  </div>
                  <div class="create-cell-content">
                    <a :href="cell.invoicePaths[0]">{{cell.invoicePaths[0].split('/').pop()}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="tabsValue != 'handled' && !isCreated" class="create-invoice-form">
              <h4>创建发票</h4>
              <div
                class="create-invoice-cell"
                v-for="(item, index) in emailInvoiceList"
                :key="index"
              >
                <div
                  v-if="emailInvoiceList.length != 1"
                  @click="deleteCell(index)"
                  class="delete-cell"
                >
                  <span class="el-icon-delete"></span>
                </div>
                <div class="create-cell">
                                    <span style="color: white" class="pre-icon"
                                    >*</span
                                    >
                  <div class="create-cell-title">邮箱</div>
                  <div class="create-cell-content">
                    {{ emailDetail.receiver }}
                  </div>
                </div>
                <div class="create-cell">
                  <span class="pre-icon">*</span>
                  <div class="create-cell-title">
                    发票代码
                  </div>
                  <div class="create-cell-content">
                    <el-input
                      size="mini"
                      minlength="5"
                      type="number"
                      v-model="item.invoiceCode"
                      placeholder="请输入发票代码"
                    ></el-input>
                  </div>
                </div>
                <div class="create-cell">
                  <span class="pre-icon">*</span>
                  <div class="create-cell-title">
                    发票号码
                  </div>
                  <div class="create-cell-content">
                    <el-input
                      minlength="5"
                      type="number"
                      size="mini"
                      v-model="item.invoiceNo"
                      placeholder="请输入发票号码"
                    ></el-input>
                  </div>
                </div>
                <div class="create-cell">
                  <span class="pre-icon">*</span>
                  <div class="create-cell-title">
                    开票日期
                  </div>
                  <div class="create-cell-content">
                    <el-date-picker
                      size="mini"
                      v-model="item.invoiceDate"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </div>
                </div>
                <div class="create-cell">
                  <span class="pre-icon">*</span>
                  <div class="create-cell-title">
                    发票校验码
                  </div>
                  <div class="create-cell-content">
                    <el-input
                      maxlength="25"
                      type="text"
                      size="mini"
                      v-model="item.invoiceCheckCode"
                      placeholder="请输入发票校验码"
                    ></el-input>
                  </div>
                </div>

                <div v-if="false" class="create-cell">
                  <span class="pre-icon">*</span>
                  <div class="create-cell-title">
                    开票金额（不含税）
                  </div>
                  <div class="create-cell-content">
                    <el-input
                      maxlength="5"
                      type="number"
                      size="mini"
                      v-model="item.invoiceAmount"
                      placeholder="请输入开票金额（不含税）"
                    ></el-input>
                  </div>
                </div>
                <div class="create-cell">
                  <span class="pre-icon">*</span>
                  <div class="create-cell-title">发票PDF</div>
                  <div class="create-cell-content">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      multiple
                      :limit="1"
                      :action="importFileUrl"
                      :before-upload="beforeUpload"
                      :on-remove="
                                                (res, file) => {
                                                    handleRemove(
                                                        res,
                                                        file,
                                                        index
                                                    );
                                                }
                                            "
                      :file-list="invoicePaths"
                      :on-success="
                                                (res, file) => {
                                                    uploadSuccess(
                                                        res,
                                                        file,
                                                        index
                                                    );
                                                }
                                            "
                      :auto-upload="true"
                    >
                      <el-button
                        slot="trigger"
                        size="mini"
                        type="primary"
                        plain
                      >点击选择发票pdf
                      </el-button
                      >
                      <span> </span>
                    </el-upload>
                  </div>
                </div>
              </div>

              <div class="create-btn">
                <el-button
                  @click="addInvoice"
                  type="primary"
                  size="mini"
                >立即创建
                </el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    components: {},
    props: ["emailDetail", "tabsValue"],
    data() {
      return {
        multiple: false,
        isException: false,
        importFileUrl: this.requestUrl.feedback.feedbackUpload,
        emailInvoiceList: [
          {
            emailRecordId: this.emailDetail.emailRecordId,
            receiver: this.emailDetail.receiver,
            invoiceCode: "",
            invoiceNo: "",
            invoiceDate: "",
            // invoiceAmount: "0",
            invoiceCheckCode: "",
            invoicePaths: [],
          },
        ],
        invoiceBaseInfos: [],
        invoicePaths: [],
        isCreated: false
      };
    },
    methods: {
      // 删除创建中发票
      deleteCell(index) {
        this.emailInvoiceList.splice(index, 1);
      },
      // 上传成功
      uploadSuccess(res, file, index) {
        this.emailInvoiceList[index].invoicePaths = [res.data];
      },
      beforeUpload(file) {
        const isPdf =
          file.type === "application/pdf";
        // const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isPdf) {
          this.$message.error("请上传pdf格式文件！");
        }
        // if (!isLt2M) {
        //     this.$message.error(
        //         "请上传2M以内的图片，支持jpg、jpeg、png、gif格式的图片！"
        //     );
        // }
        // return isJPGOrideo && isLt2M;
        return isPdf;
      },
      // 批量标记发票异常
      handleException() {
        this.FetchPut(this.requestUrl.email.exception, this.emailDetail.emailRecordId)
          .then((res) => {
            if (res.errno === "0") {
              this.isException = true;
              this.$message.success("已标记发票异常");
            } else {
              this.isException = false;
              this.$message.error(res.errmsg);
            }
          })
          .catch((err) => {
            this.$message(err);
          });
      },
      // 创建发票
      addInvoice() {
        this.emailInvoiceList.forEach((item) => {
          if (item.invoiceCheckCode) {
            item.invoiceCheckCode = item.invoiceCheckCode.replaceAll(" ", "")
          }
          if (!item.invoiceCode) {
            this.$message.warning("发票代码不能为空");
            return false
          } else if (!item.invoiceNo) {
            this.$message.warning("发票号码不能为空");
            return false
          } else if (!item.invoiceDate) {
            this.$message.warning("开票日期不能为空");
            return false
          } else if (!item.invoiceCheckCode) {
            this.$message.warning("发票校验码不能为空");
            return false
          }  else if (!/^[A-Za-z0-9]+$/.test(item.invoiceCheckCode)) {    
            this.$message.warning("发票校验码请输入字母和数字");
            return false
          } else if (!item.invoicePaths.length) {
            this.$message.warning("发票PDF不能为空");
            return false
          } else {
            item["invoiceDate"] = new Date(
              item["invoiceDate"]
            ).getTime();
            item.emailRecordId = this.emailDetail.emailRecordId;
          }
          this.FetchPost(
            this.requestUrl.email.createInvoice,
            this.emailInvoiceList
          )
            .then((res) => {
              if (res.errno === "0") {
                this.invoiceBaseInfos = this.emailInvoiceList;
                this.isCreated = true;
                this.$emit("detailReset");
              } else {
                this.$message.error(res.errmsg);
              }
            })
            .catch((err) => {
              this.$message(err);
            });
        });
      },
      // 下载附件
      downloadAtt(urlList) {
        urlList.forEach((item) => {
          window.location.href =
            this.requestUrl.email.download +
            "?filePath=" +
            encodeURI(item);
        });
      },
      submitUpload() {
        this.$refs.upload.submit();
      },
      handleRemove(file, fileList, index) {
        this.emailInvoiceList[index].invoicePaths = [];
      },
      showEmailList() {
        this.isCreated = false;
        this.isException = false;
        this.$emit("switchEmailVisible", {});
      },
      changeDate(datetimes, type) {
        let date = new Date(datetimes);
        let Y = date.getFullYear() + "-";
        let M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        let D =
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          " ";
        let h =
          (date.getHours() > 9
            ? date.getHours()
            : "0" + date.getHours()) + ":";
        let m =
          (date.getMinutes() > 9
            ? date.getMinutes()
            : "0" + date.getMinutes()) + ":";
        let s =
          date.getSeconds() > 9
            ? date.getSeconds()
            : "0" + date.getSeconds();
        //      年 月 日 时 分 秒
        if (type == "time") {
          return Y + M + D + h + m + s;
        } else {
          return Y + M + D;
        }

      },
    },
    mounted() {
      console.log(this.tabsValue)
    },
  };
</script>

<style lang="less">
  .el-upload-list__item-name {
    width: 230px !important;
  }

  .email-detail {
    .header-title {
      cursor: pointer;
    }
    .email-detail-content {
      display: flex;
      justify-content: space-between;

      .email-content {
        flex: auto;
        height: calc(100vh - 130px);
        overflow: auto;
        margin-right: 16px;
        .email-content-header {
          margin-top: 16px;
          margin-left: 16px;
          background-color: white;
          padding: 16px;
          .subject {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 8px;
          }
        }
        .email-content-html {
          margin-top: 16px;
          margin-left: 16px;
          background-color: white;
          padding: 16px;
          min-height: 300px;
        }
        .email-content-attachments {
          margin-top: 16px;
          margin-left: 16px;
          background-color: white;
          padding: 16px;
          .email-content-attachments-header {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 16px;
            span {
              margin-right: 16px;
            }
            .downloadAtt {
              color: #d0aa70;
            }
          }
          .attachments-cell {
            font-size: 12px;
            a {
              color: #d0aa70;
              text-decoration: none;
            }
          }
        }
      }
      .create-invoice {
        flex: none;
        width: 380px;
        height: calc(100vh - 178px);
        margin-top: 16px;
        margin-right: 16px;
        background-color: white;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .list-box {
          flex: auto;
          overflow-y: auto;
          padding-right: 16px;
          .create-invoice-form {
            width: 380px;
            margin-top: 16px;
            h4 {
              text-align: center;
              margin-bottom: 16px;
            }
            .delete-cell {
              margin-top: 16px;
              cursor: pointer;
              color: red;
              text-align: right;
            }
            .create-cell {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 12px;

              .create-cell-content {
                flex: auto;
                .el-input__inner {
                  width: 100% !important;
                }
              }

              .pre-icon {
                color: red;
              }
              .create-cell-title {
                flex: none;
                width: 80px;
              }
            }
            .create-btn {
              margin-top: 32px;
              text-align: center;
            }
          }
          .invoice-list {
            display: flex;
            flex-direction: column;
            .invoice-cell {
              .create-cell {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 12px;
                .create-cell-title {
                  flex: none;
                  width: 80px;
                }
                .create-cell-content {
                  flex: auto;
                }
              }
            }
          }
        }

        .add-btn {
          padding-top: 30px;
          .el-button {
            width: 100%;
          }
        }
      }
    }
  }
</style>


