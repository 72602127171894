<template>
    <el-container>
        <el-aside width="64px"><LeftMenu></LeftMenu></el-aside>
        <el-container>
            <el-header>
                <div class="header-title">主页</div>
            </el-header>
            <el-main>
                <div class="statistics-top">
                    <div class="top-box">
                        <div class="users-number">
                            <div class="users-number-desc">
                                <span>总用户数</span>
                                <!-- <i
                                    class="arsenal_icon arsenalinfo-circle-solid"
                                ></i> -->
                            </div>
                            <div class="users-number-title">
                                <span v-if="usersChartDataEmpty == false">{{
                                    totalUser
                                }}</span>
                            </div>
                            <div class="users-number-chart">
                                <ve-line
                                    :data="userChartValuesObj"
                                    :tooltip-visible="tooltipVisible"
                                    :data-empty="usersChartDataEmpty"
                                    :settings="userChartValuesChartSettings"
                                    width="100%"
                                    height="150px"
                                    class="ve-line"
                                    :legend-visible="false"
                                    :grid="topChartGrid"
                                    :xAxis="topChartxAxis"
                                    :yAxis="yAxis"
                                    :colors="usersChartColors"
                                    v-loading="loading"
                                ></ve-line>
                            </div>
                            <div class="users-number-detail">
                                <div class="users-today">
                                    <span class="lable">今日新增</span>
                                    <span class="value">{{ todayUser }}</span>
                                </div>
                                <div class="user-daily-activate">
                                    <span class="lable">日活</span>
                                    <span class="value">{{ dailyLife }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-box">
                        <div class="source-number">
                            <div class="source-number-desc">
                                <span>授权手机号数</span>
                                <!-- <i
                                    class="arsenal_icon arsenalinfo-circle-solid"
                                ></i> -->
                            </div>
                            <div class="source-number-title">
                                <span v-if="usersChartDataEmpty == false">{{
                                    mobileAuthUser
                                }}</span>
                            </div>
                        </div>
                        <div class="source-number">
                            <div class="source-number-desc">
                                <span>开通智能邮箱数</span>
                                <!-- <i
                                    class="arsenal_icon arsenalinfo-circle-solid"
                                ></i> -->
                            </div>
                            <div class="source-number-title">
                                <span v-if="usersChartDataEmpty == false">{{
                                    cusEmailAuthUser
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="top-box">
                        <div class="invoice-number">
                            <div class="invoice-number-desc">
                                <span>发票总数</span>
                                <!-- <i
                                    class="arsenal_icon arsenalinfo-circle-solid"
                                ></i> -->
                            </div>
                            <div class="invoice-number-title">
                                <span v-if="usersChartDataEmpty == false">{{
                                    totalInvoice
                                }}</span>
                            </div>
                            <div class="invoice-number-chart">
                                <ve-line
                                    :data="invoiceChartValuesObj"
                                    :data-empty="usersChartDataEmpty"
                                    :tooltip-visible="tooltipVisible"
                                    :settings="chartSettings"
                                    width="100%"
                                    height="150px"
                                    :legend-visible="false"
                                    :grid="topChartGrid"
                                    :xAxis="topChartxAxis"
                                    :yAxis="yAxis"
                                    :colors="invoiceChartColors"
                                    v-loading="loading"
                                ></ve-line>
                            </div>
                            <div class="invoice-number-detail">
                                <div class="invoice-today">
                                    <span class="lable">今日新增</span>
                                    <span class="value">{{
                                        todayInvoice
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="top-box2">
                        <div class="invoice-source-detail">
                            <div class="invoice-source-desc">
                                <span>发票来源</span>
                                <!-- <i
                                    class="arsenal_icon arsenalinfo-circle-solid"
                                ></i> -->
                            </div>
                            <div class="invoice-source-content">
                                <div
                                    class="invoice-source-content-box box-line"
                                >
                                    <div class="list-item">
                                        <div class="list-item-label">
                                            个人邮箱
                                        </div>
                                        <div class="list-item-value">
                                            {{ personalEmailInvoice }}
                                        </div>
                                    </div>
                                    <div class="list-item">
                                        <div class="list-item-label">
                                            智能邮箱
                                        </div>
                                        <div class="list-item-value">
                                            {{ cusEmailInvoice }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="invoice-source-content-box box-line"
                                >
                                    <div class="list-item">
                                        <div class="list-item-label">
                                            微信卡包
                                        </div>
                                        <div class="list-item-value">
                                            {{ wxCardInvoice }}
                                        </div>
                                    </div>
                                    <div class="list-item">
                                        <div class="list-item-label">
                                            支付宝卡包
                                        </div>
                                        <div class="list-item-value">
                                            {{ alipayCardInvoice }}
                                        </div>
                                    </div>
                                </div>
                                <div class="invoice-source-content-box">
                                    <div class="list-item">
                                        <div class="list-item-label">
                                            扫码导入
                                        </div>
                                        <div class="list-item-value">
                                            {{ scanInvoice }}
                                        </div>
                                    </div>
                                    <div class="list-item">
                                        <div class="list-item-label">
                                            滴滴推送
                                        </div>
                                        <div class="list-item-value">
                                            {{ didiPushInvoice }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="statistics-second">
                    <div @click="goto('Monitor')" class="second-box">
                        <div class="text-title">
                            异常发票（{{ exceptionInvoice }}）
                        </div>
                    </div>
                    <div @click="goto('Email')" class="second-box second-box-m">
                        <div class="text-title">
                            未处理邮件（{{ exceptionEmail }}）
                        </div>
                    </div>
                    <div @click="goto('Feedback')" class="second-box">
                        <div class="text-title">
                            意见反馈（{{ totalFeedback }}）
                        </div>
                    </div>
                </div>
                <div class="statistics-three">
                    <div class="statistics-three-box">
                        <div class="statistics-three-tabs">
                            <div class="three-tabs-header">
                                <div class="statistics-tabs-title">
                                    <div class="tabs-select-menu">
                                        <div
                                            class="tabs-menu-item"
                                            @click="
                                                invoiceChartTab = false;
                                                userChartTab = true;
                                            "
                                            :class="
                                                userChartTab ? 'active' : ''
                                            "
                                        >
                                            <span class="tabs-menu-text"
                                                >用户数</span
                                            >
                                        </div>
                                        <div
                                            class="tabs-menu-item"
                                            @click="
                                                userChartTab = false;
                                                invoiceChartTab = true;
                                            "
                                            :class="
                                                invoiceChartTab ? 'active' : ''
                                            "
                                        >
                                            <span class="tabs-menu-text"
                                                >发票数</span
                                            >
                                        </div>
                                    </div>
                                    <div class="time-select-menu">
                                        <div class="dateSelection">
                                            <span
                                                :class="
                                                    currentDate == 1
                                                        ? 'isActive'
                                                        : ''
                                                "
                                                @click="todayData"
                                                >今日</span
                                            >
                                            <span
                                                :class="
                                                    currentDate == '2'
                                                        ? 'isActive'
                                                        : ''
                                                "
                                                @click="currentWeek"
                                                >本周</span
                                            >
                                            <span
                                                :class="
                                                    currentDate == '3'
                                                        ? 'isActive'
                                                        : ''
                                                "
                                                @click="currentMouth"
                                                >本月</span
                                            >
                                            <span
                                                :class="
                                                    currentDate == '4'
                                                        ? 'isActive'
                                                        : ''
                                                "
                                                @click="currentYear"
                                                >全年</span
                                            >
                                            <el-date-picker
                                                v-model="dateSeleteData"
                                                @change="datePickerChange"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                            >
                                            </el-date-picker>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="statistics-tabs-content">
                                <div
                                    v-if="userChartTab"
                                    class="users-info-content"
                                >
                                    <div ref="ucb1" class="users-chart-box">
                                        <Histogram
                                            :data="usersLargeChartData"
                                            :data-empty="
                                                usersLargeChartDataEmpty
                                            "
                                            width="100%"
                                            :extend="midpeopleNumberExtend"
                                            :height="bottomHeight + 'px'"
                                            :settings="usersLargeChartSettings"
                                            :colors="usersLargeChartColors"
                                            v-loading="loading"
                                        ></Histogram>
                                    </div>
                                    <div class="users-detail-box">
                                        <div class="detail-title">明细</div>
                                        <div class="detail-list">
                                            <div class="label">用户数</div>
                                            <div class="value">
                                                {{ detailInfo.totalUser }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">日活</div>
                                            <div class="value">
                                                {{ detailInfo.dailyLife }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">
                                                授权手机号数
                                            </div>
                                            <div class="value">
                                                {{ detailInfo.mobileAuthUser }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">
                                                开通智能邮箱数
                                            </div>
                                            <div class="value">
                                                {{
                                                    detailInfo.cusEmailAuthUser
                                                }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">活跃次数</div>
                                            <div class="value">
                                                {{ detailInfo.totalLogin }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">
                                                登录异常次数
                                            </div>
                                            <div class="value">
                                                {{ detailInfo.errorLogin }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="invoiceChartTab"
                                    class="users-info-content"
                                    ref="ucb2"
                                >
                                    <div class="users-chart-box">
                                        <Histogram
                                            :data="invoiceLargeChartData"
                                            width="100%"
                                            :extend="midpeopleNumberExtend"
                                            :height="bottomHeight + 'px'"
                                            :settings="inVoiceChartSettings"
                                            :colors="invoiceLargeChartColors"
                                            v-loading="loading"
                                        ></Histogram>
                                    </div>
                                    <div class="users-detail-box">
                                        <div class="detail-title">明细</div>
                                        <div class="detail-list">
                                            <div class="label">发票数</div>
                                            <div class="value">
                                                {{ detailInfo.totalInvoice }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">个人邮箱</div>
                                            <div class="value">
                                                {{
                                                    detailInfo.personalEmailInvoice
                                                }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">智能邮箱</div>
                                            <div class="value">
                                                {{ detailInfo.cusEmailInvoice }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">微信卡包</div>
                                            <div class="value">
                                                {{ detailInfo.wxCardInvoice }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">支付宝卡包</div>
                                            <div class="value">
                                                {{
                                                    detailInfo.alipayCardInvoice
                                                }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">扫码导入</div>
                                            <div class="value">
                                                {{ detailInfo.scanInvoice }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">滴滴推送</div>
                                            <div class="value">
                                                {{ detailInfo.didiPushInvoice }}
                                            </div>
                                        </div>
                                        <div class="detail-list">
                                            <div class="label">
                                                好友攒票任务
                                            </div>
                                            <div class="value">
                                                {{ detailInfo.collectionTask }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import LeftMenu from "./../../components/menu/leftMenu.vue";
import VeLine from "v-charts/lib/line.common";
import Histogram from "v-charts/lib/histogram.common";
import "v-charts/lib/style.css";

export default {
    name: "Home",
    components: {
        LeftMenu,
        VeLine,
        Histogram,
    },
    data() {
        this.chartSettings = {
            area: true,
        };
        this.userChartValuesChartSettings = {
            area: true,
        };

        (this.grid = {
            show: true,
            top: "5%",
            left: "-22%",
            right: "-13%",
            backgroundColor: "#ffffff",
            borderColor: "#fff",
        }),
            (this.topChartGrid = {
                show: true,
                top: "5%",
                left: "-10%",
                backgroundColor: "#ffffff",
                borderColor: "#fff",
                area: true,
            }),
            (this.topChartxAxis = {
                type: "category",
                show: false,
            });
        this.xAxis = {
            type: "category",
            show: false
        };
        this.grid = {
            right: 10,
            top: 5,
            left: 5,
        };
        this.yAxis = {
            type: "value",
            show: false,
        };
        this.recognitionRatecolors = ["#fec70a"];
        // this.messageNumberColors = ["#a626e8"];
        this.usersChartColors = ["#8f66dd"];
        this.invoiceChartColors = ["#409eff"];
        this.invoiceLargeChartColors = ["#409eff"];
        this.peoplelargecolors = ["#409eff", "#ffc922", "#ffc922"];
        this.usersLargeChartColors = ["#dec39a", "#1E90FF", "#DC143C"];
        this.usersLargeChartSettings = {
            showLine: ["活跃次数", "异常次数"],
            axisSite: { right: ["活跃次数", "异常次数"] },
            yAxisType: ["KMB", "KMB"],
            yAxisName: ["用户数", "活跃次数", "异常次数"],
        };
        this.inVoiceChartSettings = {
            yAxisName: ["发票数"],
        };
        return {
            bottomHeight: 100,
            tooltipVisible: false,
            midpeopleNumberExtend: {
                barWidth: 20,
                legend: {
                    show: true,
                },
                xAxis: {
                    name: "日期",
                    nameLocation: 'end',
                    verticalAlign: 'end',
                    nameTextStyle: {
                        align: 'right',
                        padding: [40,15,-30,0]
                    }
                }
               
            },
            // 概要信息
            totalUser: "",
            dailyLife: "",
            mobileAuthUser: "",
            cusEmailAuthUser: "",
            totalInvoice: "",
            personalEmailInvoice: "",
            cusEmailInvoice: "",
            wxCardInvoice: "",
            alipayCardInvoice: "",
            scanInvoice: "",
            didiPushInvoice: "",
            todayUser: "",
            userChartValuesObj: {},
            todayInvoice: "",
            invoiceChartValuesObj: {},
            exceptionInvoice: "",
            exceptionEmail: "",
            totalFeedback: "",
            // 时间选择
            currentDate: 3, // 时间类型 0:自定义 1：本日 2：本周 3：本月 4：本年
            dateSeleteData: [],
            // 详细信息
            detailInfo: {
                alipayCardInvoice: 0,
                cusEmailAuthUser: 0,
                cusEmailInvoice: 0,
                dailyLife: 0,
                didiPushInvoice: 0,
                errorLogin: 0,
                errorLoginValues: [],
                mobileAuthUser: 0,
                personalEmailInvoice: 0,
                scanInvoice: 0,
                totalInvoice: 0,
                totalInvoiceValues: [],
                totalLogin: 0,
                totalLoginValues: [],
                totalUser: 0,
                totalUserValues: [],
                wxCardInvoice: 0,
                collectionTask: 0,
            },

            ///
            loading: false,
            loading2: false,
            fullHeight: "",
            topChartHeight: null,
            topDescHeight: null,
            largeChartHeight: null,
            // channelSeleteData: { id: "", name: "全部" },
            // channelList: [{ id: "", name: "全部" }],
            // firstResponseTimeEmpty: false,
            // firstResponseTime: { respTime: "" },
            // averageResponseTimeEmpty: false,
            // averageResponseTime: { respTime: "" },

            usersChartDataEmpty: false,
            usersChartDataExtend: {
                series: {
                    center: [112, 92],
                    areaStyle: { color: "#fffae2" },
                    lineStyle: { color: "#ffc922" },
                },
            },
            usersLargeChartDataEmpty: false,
            usersLargeChartData: {
                columns: ["日期", "用户数", "活跃次数", "异常次数"],
                rows: [],
            },
            invoiceLargeChartData: {
                columns: ["日期", "发票数"],
                rows: [],
            },
            setTime: "",
            userChartTab: true,
            invoiceChartTab: false,
        };
    },
    methods: {
        goto(type) {
            this.$router.push({
                name: type,
            });
        },
        changeFixed(fullHeight) {
            this.fullHeight =
                document.documentElement.offsetHeight ||
                document.body.offsetHeight; //默认值
            //动态修改样式
            let largeChartHeight = parseInt(fullHeight * "0.8" - 320);
            this.largeChartHeight = largeChartHeight;
        },
        getSummary() {
            this.FetchGet(this.requestUrl.index.summary, {})
                .then((res) => {
                    if (res.errno === "0") {
                        this.totalUser = res.data.totalUser;
                        this.dailyLife = res.data.dailyLife;
                        this.mobileAuthUser = res.data.mobileAuthUser;
                        this.cusEmailAuthUser = res.data.cusEmailAuthUser;
                        this.totalInvoice = res.data.totalInvoice;
                        this.personalEmailInvoice =
                            res.data.personalEmailInvoice;
                        this.cusEmailInvoice = res.data.cusEmailInvoice;
                        this.wxCardInvoice = res.data.wxCardInvoice;
                        this.alipayCardInvoice = res.data.alipayCardInvoice;
                        this.scanInvoice = res.data.scanInvoice;
                        this.didiPushInvoice = res.data.didiPushInvoice;
                        this.todayUser = res.data.todayUser;
                        let userChartValuesRows = [];
                        res.data.userChartValues.forEach((item) => {
                            item.日期 = item.showTime;
                            item.访问用户 = item.showValue;
                            userChartValuesRows.push(item);
                        });
                        this.userChartValuesObj = {
                            columns: ["日期", "访问用户"],
                            rows: userChartValuesRows,
                        };
                        this.todayInvoice = res.data.todayInvoice;
                        let invoiceChartValuesRows = [];
                        res.data.invoiceChartValues.forEach((item) => {
                            item.日期 = item.showTime;
                            item.发票数 = item.showValue;
                            invoiceChartValuesRows.push(item);
                        });
                        this.invoiceChartValuesObj = {
                            columns: ["日期", "发票数"],
                            rows: invoiceChartValuesRows,
                        };
                        this.exceptionInvoice = res.data.exceptionInvoice;
                        this.exceptionEmail = res.data.exceptionEmail;
                        this.totalFeedback = res.data.totalFeedback;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        getDetail(type, startTime, endTime) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let url = "";
            if (type == "0") {
                url =
                    this.requestUrl.index.detail +
                    "?startTime=" +
                    startTime +
                    "&endTime=" +
                    endTime +
                    "&type=" +
                    type;
            } else {
                url = this.requestUrl.index.detail + "?type=" + type;
            }
            this.FetchGet(url, {})
                .then((res) => {
                    if (res.errno === "0") {
                        let tampObj = res.data;
                        let usersLargeChartDataTampArr = [];
                        let invoiceLargeChartDataTampArr = [];
                        this.detailInfo = tampObj;
                        if (tampObj.totalUserValues != null) {
                            tampObj.totalUserValues.forEach((item, index) => {
                                usersLargeChartDataTampArr.push({
                                    日期: item.showTime,
                                    用户数: item.showValue,
                                    活跃次数:
                                        tampObj.totalLoginValues[index]
                                            .showValue,
                                    异常次数:
                                        tampObj.errorLoginValues[index]
                                            .showValue,
                                });
                            });
                            tampObj.totalInvoiceValues.forEach((item) => {
                                invoiceLargeChartDataTampArr.push({
                                    日期: item.showTime,
                                    发票数: item.showValue,
                                });
                            });
                        }
                        this.midpeopleNumberExtend.xAxis.name = (tampObj.timeUnit == "天"?"日":tampObj.timeUnit);
                        this.usersLargeChartData = {
                            columns: ["日期", "用户数", "活跃次数", "异常次数"],
                            rows: usersLargeChartDataTampArr,
                        };
                        this.invoiceLargeChartData = {
                            columns: ["日期", "发票数"],
                            rows: invoiceLargeChartDataTampArr,
                        };
                    } else {
                        this.$message.error(res.errmsg);
                    }
                    loading.close();
                })
                .catch((err) => {
                    loading.close();
                    this.$message(err);
                });
        },
        getDay(day) {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * day;
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },

        // getStatisticsData(startTime, endTime) {
        //     this.getDetail(type,startTime, endTime);
        // },
        todayData() {
            this.currentDate = 1;
            this.getDetail(1);
        },
        currentWeek() {
            this.currentDate = 2;
            this.getDetail(2);
        },
        currentMouth() {
            this.currentDate = 3;
            this.getDetail(3);
        },
        currentYear() {
            this.currentDate = 4;
            this.getDetail(4);
        },

        datePickerChange() {
            this.currentDate = 0;
            let d = this.dateSeleteData[0];
            let startTimeDated =
                d.getFullYear() +
                "-" +
                (d.getMonth() + 1) +
                "-" +
                d.getDate() +
                " " +
                d.getHours() +
                ":" +
                d.getMinutes() +
                ":" +
                d.getSeconds();
            let startTimeDate = new Date(
                Date.parse(startTimeDated.replace(/-/g, "/"))
            );
            let startTime = startTimeDate.getTime();
            let end = this.dateSeleteData[1];
            let e = new Date(end);
            let endday =
                e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate();
            let timestamp = new Date().getTime();
            let yy = new Date().getFullYear();
            let mm = new Date().getMonth() + 1;
            let dd = new Date().getDate();
            let today = yy + "-" + mm + "-" + dd;
            let todaystamp = Date.parse(today);
            let enddaystamp = Date.parse(endday);
            let endTime = "";
            if (todaystamp === enddaystamp) {
                endTime = timestamp;
            } else {
                let endTimeDated =
                    e.getFullYear() +
                    "-" +
                    (e.getMonth() + 1) +
                    "-" +
                    e.getDate() +
                    " " +
                    "23:59:59";
                let endTimeDate = new Date(
                    Date.parse(endTimeDated.replace(/-/g, "/"))
                );
                endTime = endTimeDate.getTime();
            }
            if (startTime > timestamp || endTime > timestamp) {
                this.dateSeleteData = [];
                this.$message.error("不能穿越哦，请重新选择：");
            } else {
                this.getDetail(0, startTime, endTime);
                // this.getStatisticsData(startTime, endTime);
            }
        },
    },
    mounted() {
        this.getSummary(); // 获取概要统计数据
        this.currentMouth(); // 详细统计数据
        this.changeFixed();
        const that = this;
        that.$nextTick(() => {
                 that.bottomHeight = that.$refs.ucb1.offsetHeight;
            })
        window.onresize = () => {
            that.$nextTick(() => {
                 if (this.userChartTab) {
                     that.bottomHeight = that.$refs.ucb1.offsetHeight;
                 } else {
                     that.bottomHeight = that.$refs.ucb2.offsetHeight;
                 }
                 
            })
           
            return (() => {
                window.fullHeight = document.documentElement.clientHeigh;
                that.fullHeight = window.fullHeight;
            })();
        };
    },
    watch: {
        //如果 `fullHeight ` 发生改变，这个函数就会运行
        fullHeight: function (val) {
            if (!this.timer) {
                this.fullHeight = val;
                this.changeFixed(this.fullHeight);
                this.timer = true;
                let that = this;
                setTimeout(function () {
                    //频繁触发 resize 函数，会导致页面很卡
                    that.timer = false;
                }, 100);
            }
        },
    },
};
</script>

<style lang="less">
@import "./../../assets/less/home/home.less";
</style>
