
const requestUrl = {
    auth: { // 用户
        login: '/ykb-api/auth/login', // 登录 POST
        logout:"/ykb-api/auth/logout", // 退出 DELETE
    },
    index: { // 统计
        summary: "/ykb-api/index/summary", // 统计概要信息 GET
        detail: "/ykb-api/index/detail", // 统计详细信息 GET
    },
    invoice: { // 发票监控
        company: "/ykb-api/invoice/company", // 根据关键字搜索公司 GET
        exceptionTypes: "/ykb-api/invoice/exception-types",// 获取所有的问题类型 GET
        export: "/ykb-api/invoice/export", // 导出发票 POST
        groups: "/ykb-api/invoice/groups",// 获取默认的发票分组 GET
        invoiceAll: "/ykb-api/invoice/list/all", // 分页查询所有发票 POST
        invoiceException: "/ykb-api/invoice/list/exception", // 分页查询异常发票 POST
        sources: "/ykb-api/invoice/sources", // 获取所有的发票来源 GET
        count: "/ykb-api/invoice/count", // 获取发票数量，异常及所有 GET
        invoiceSave: "/ykb-api/invoice/update", // 发票保存 POST
        detail: "/ykb-api/invoice/detail/" // 查询发票详细信息 GET /invoice/detail/{invoiceId}
    },
    email: { // 邮箱监控
        detail: "/ykb-api/email", // 查看邮件详细信息 /email/{emailRecordId} GET
        createInvoice: "/ykb-api/email/create/invoice",// 根据邮件信息创建发票 POST
        cusCount: "/ykb-api/email/cus-count",// 获取智能邮箱邮件的数量 GET
        ignore: "/ykb-api/email/ignore", // 忽略某邮件 /email/ignore/{emailRecordId} PUT
        ignorebatch: "/ykb-api/email/ignorebatch", // 批量忽略邮件 POST
        exceptionBatch: "/ykb-api/email/sign/exception/batch", // 批量发票标记  POST
        exception: "/ykb-api/email/sign/exception", // 发票标记 PUT
        emailToInvoice: "/ykb-api/email/invoices", // 获取某邮件对应的发票 /email/invoices/{emailRecordId} GET
        emailHandled: "/ykb-api/email/list/cus/handled",// 智能邮箱已处理的邮件 GET
        emailIgnored: "/ykb-api/email/list/cus/ignored",// 智能邮箱忽略的邮件 GET
        emailUnhandled: "/ykb-api/email/list/cus/unhandled",// 智能邮箱未处理的邮件 GET
        emailException: "/ykb-api/email/list/cus/sign/exception",// 智能邮箱异常发票邮件 GET
        download: "/ykb-api/email/attachment/download", // 下载附件 GET
        cancel: "/ykb-api/email/cancel/", // 撤销创建发票 POST
    },
    feedback: { // 意见反馈
        detail: "/ykb-api/feedback/", // /feedback/{feedbackId} 根据ID查询意见反馈信息 GET
        list: "/ykb-api/feedback/list", // 分页查询意见反馈列表 GET
        feedbackReply: "/ykb-api/feedback/reply/",// /feedback/reply/{feedbackId} 回复用户的意见反馈 POST
        feedbackUpload: "/ykb-api/feedback/upload", // upload POST
    }
}

export { requestUrl };
