<template>
    <el-container>
        <el-aside width="64px"><LeftMenu></LeftMenu></el-aside>
        <el-container v-show="visibleType == 'list'">
            <el-header>
                <div class="header-title">发票监控</div>
                <div class="condition">
                    <el-tag
                        size="mini"
                        closable
                        v-if="conditionSelectFapiaoType != -1"
                        @close="closeCondition('selectFapiaoType')"
                    >
                        发票类型：{{
                            conditionSelectFapiaoType == 0 ? "普票" : "专票"
                        }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="conditionSourceConfiArr.length > 0"
                        @close="closeCondition('invoiceSource')"
                    >
                        发票来源：{{
                            conditionSourceConfiArr.join("、").substring(0, 8)
                        }}{{
                            conditionSourceConfiArr.join("、").length > 8
                                ? "..."
                                : ""
                        }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="conditionImportTimeRange.length > 0"
                        @close="closeCondition('importTimeRange')"
                    >
                        入库时间：{{
                            importTimeRangeConfi(conditionImportTimeRange)
                        }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="conditionQwValue.length > 0"
                        @close="closeCondition('qwValue')"
                    >
                        问题类型：{{ qwValueConfi(conditionQwValue) }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="
                            conditionAcountRangeType == 2 &&
                            (conditionFapiaoAmountStart != '' ||
                                conditionFapiaoAmountEnd != '')
                        "
                        @close="closeCondition('acountRangeType')"
                    >
                        发票金额：{{
                            conditionFapiaoAmountStart == ""
                                ? "不限"
                                : conditionFapiaoAmountStart
                        }}
                        -
                        {{
                            conditionFapiaoAmountEnd == ""
                                ? "不限"
                                : conditionFapiaoAmountEnd
                        }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="conditionCreateTimeRange.length > 0"
                        @close="closeCondition('createTimeRange')"
                    >
                        开票时间：{{
                            importTimeRangeConfi(conditionCreateTimeRange)
                        }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="conditionInvoiceTypeConfiArr.length > 0"
                        @close="closeCondition('invoiceType')"
                    >
                        发票分类：{{
                            conditionInvoiceTypeConfiArr
                                .join("、")
                                .substring(0, 8)
                        }}{{
                            conditionInvoiceTypeConfiArr.join("、").length > 8
                                ? "..."
                                : ""
                        }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="conditionBuyerName.length > 0"
                        @close="closeCondition('buyerName')"
                    >
                        购方名称：{{
                            conditionBuyerName.join("、").substring(0, 8)
                        }}
                        {{
                            conditionBuyerName.join("、").length > 8
                                ? "..."
                                : ""
                        }}
                    </el-tag>
                    <el-tag
                        size="mini"
                        closable
                        v-if="conditionRecoverableName.length > 0"
                        @close="closeCondition('recoverableName')"
                    >
                        销方名称：{{
                            conditionRecoverableName.join("、").substring(0, 8)
                        }}
                        {{
                            conditionRecoverableName.join("、").length > 8
                                ? "..."
                                : ""
                        }}
                    </el-tag>
                </div>
                <div class="serach-box">
                    <el-button type="text" @click="switchDropdown()"
                        >高级筛选</el-button
                    >
                </div>
            </el-header>
            <div class="dropdown-box" v-show="isShowDropdownBox">
                <div class="search-content">
                    <div class="dropdown-box-left">
                        <div class="form-item">
                            <div class="form-item-label">发票类型</div>
                            <div class="form-item-content">
                                <el-radio v-model="selectFapiaoType" label="-1"
                                    >全部</el-radio
                                >
                                <el-radio v-model="selectFapiaoType" label="0"
                                    >普票</el-radio
                                >
                                <el-radio v-model="selectFapiaoType" label="1"
                                    >专票</el-radio
                                >
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">发票来源</div>
                            <div class="form-item-content">
                                <el-select
                                    v-model="invoiceSource"
                                    size="small"
                                    multiple
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in sourceOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">入库时间范围</div>
                            <div class="form-item-content">
                                <el-date-picker
                                    v-model="importTimeRange"
                                    size="small"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">问题类型</div>
                            <div class="form-item-content">
                                <el-select
                                    size="small"
                                    v-model="qwValue"
                                    multiple
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in qwOptions"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-box-right">
                        <div class="form-item">
                            <div class="form-item-label">发票金额范围</div>
                            <div class="form-item-content">
                                <el-radio v-model="acountRangeType" label="1"
                                    >不限</el-radio
                                >
                                <el-radio v-model="acountRangeType" label="2">
                                    自定义
                                </el-radio>
                                <el-input
                                    size="small"
                                    v-show="acountRangeType == 2"
                                    class="fapiao-amount"
                                    v-model="fapiaoAmountStart"
                                    placeholder="金额"
                                ></el-input>
                                <span v-show="acountRangeType == 2"> - </span>
                                <el-input
                                    size="small"
                                    v-show="acountRangeType == 2"
                                    class="fapiao-amount"
                                    v-model="fapiaoAmountEnd"
                                    placeholder="金额"
                                ></el-input>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">开票时间范围</div>
                            <div class="form-item-content">
                                <el-date-picker
                                    v-model="createTimeRange"
                                    size="small"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                >
                                </el-date-picker>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">发票分类</div>
                            <div class="form-item-content">
                                <el-select
                                    v-model="invoiceType"
                                    size="small"
                                    multiple
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in invoiceTypeOptions"
                                        :key="item.value.name"
                                        :label="item.value.name"
                                        :value="item.value.name"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">销方名称</div>
                            <div class="form-item-content">
                                <el-select
                                    v-model="recoverableName"
                                    size="small"
                                    multiple
                                    filterable
                                    remote
                                    allow-create
                                    :remote-method="remoteMethod"
                                    :loading="remoteLoading"
                                    placeholder="请选择销方名称"
                                >
                                    <el-option
                                        v-for="item in recoverableNameOptions"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.name"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="form-item">
                            <div class="form-item-label">购方名称</div>
                            <div class="form-item-content">
                                <el-select
                                    v-model="buyerName"
                                    size="small"
                                    multiple
                                    filterable
                                    remote
                                    allow-create
                                    :remote-method="remoteMethod"
                                    :loading="remoteLoading"
                                    placeholder="请选择购方名称"
                                >
                                    <el-option
                                        v-for="item in recoverableNameOptions"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.name"
                                    >
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="dropdown-box-handle">
                    <el-button
                        @click="handleSearch('reset')"
                        size="mini"
                        type="primary"
                        plain
                        round
                        >重置</el-button
                    >
                    <el-button
                        @click="handleSearch('close')"
                        size="mini"
                        plain
                        round
                        >取消</el-button
                    >
                    <el-button
                        @click="handleSearch('submit')"
                        size="mini"
                        type="primary"
                        round
                        >筛选</el-button
                    >
                </div>
            </div>
            <el-main>
                <div id="fapiao-monitor">
                    <el-tabs
                        v-model="tabsValue"
                        @tab-click="switchTab"
                        tab-position="left"
                    >
                        <el-tab-pane name="exception">
                            <span slot="label"
                                >异常发票（{{ exceptionCount }}）</span
                            >
                            <div class="table-box">
                                <div class="table-content">
                                    <el-table
                                        ref="multipleTableE"
                                        :data="tableData"
                                        size="mini"
                                        height="calc(100vh - 140px)"
                                        tooltip-effect="dark"
                                        style="width: 100%"
                                        :default-sort="{
                                            prop: 'date',
                                            order: 'descending',
                                        }"
                                        @sort-change="sortChange"
                                        @selection-change="
                                            handleSelectionChange
                                        "
                                    >
                                        <el-table-column
                                            type="selection"
                                            width="55"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="name"
                                            label="发票"
                                            width="320"
                                        >
                                            <template slot-scope="scope">
                                                <div
                                                    :style="{
                                                        backgroundColor:
                                                            scope.row
                                                                .iconDominantColor ||
                                                            '#ebad41',
                                                    }"
                                                    class="fapiao-detail"
                                                >
                                                    <div
                                                        class="fapiao-detail-logo"
                                                    >
                                                        <img
                                                            height="42px"
                                                            width="42px"
                                                            :src="
                                                                scope.row.icon
                                                            "
                                                            alt=""
                                                            srcset=""
                                                        />
                                                    </div>
                                                    <div
                                                        class="fapiao-detail-provider"
                                                    >
                                                        <div
                                                            class="provider-company-name"
                                                        >
                                                            {{
                                                                scope.row
                                                                    .payeeShortName
                                                            }}
                                                        </div>
                                                        <div
                                                            class="fapiao-folder"
                                                        >
                                                            {{ scope.row.type }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="fapiao-detail-master"
                                                    >
                                                        <div
                                                            class="fapiao-amount"
                                                        >
                                                            ￥{{
                                                                scope.row.amount
                                                            }}
                                                        </div>
                                                        <div
                                                            class="mastyer-company-name"
                                                        >
                                                            {{
                                                                scope.row
                                                                    .payerShortName
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="invoiceType"
                                            label="发票类型"
                                            show-overflow-tooltip
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="sourceName"
                                            label="发票来源"
                                            show-overflow-tooltip
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="importTime"
                                            label="入库时间"
                                            sortable
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="checkStatusName"
                                            label="问题"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="100"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    @click.native.prevent="
                                                        switchEmailVisible(
                                                            'compile',
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                                                    type="text"
                                                    size="small"
                                                >
                                                    编辑
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>

                                <div class="table-box-footer">
                                    <div class="footer-operation">
                                        <el-checkbox
                                            :indeterminate="isIndeterminate"
                                            v-model="allPage"
                                            @change="selectAllPage"
                                            >选择全部</el-checkbox
                                        >
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            class="export-fapiao"
                                            :disabled="
                                                multipleSelection.length == 0
                                            "
                                            @click="openInputEmail"
                                            >导出选中</el-button
                                        >
                                    </div>
                                    <el-pagination
                                        background
                                        layout="total, prev, pager, next"
                                        :total="total"
                                        :page-size="pageSize"
                                        @current-change="handleCurrentChange"
                                        :current-page="currentPage"
                                    >
                                    </el-pagination>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="all">
                            <span slot="label"
                                >全部发票（{{ totalCount }}）</span
                            >
                            <div class="table-box">
                                <div class="table-content">
                                    <el-table
                                        class="allTable"
                                        ref="multipleTableA"
                                        :data="tableData"
                                        height="calc(100vh - 140px)"
                                        size="mini"
                                        tooltip-effect="dark"
                                        style="width: 100%"
                                        :default-sort="{
                                            prop: 'date',
                                            order: 'descending',
                                        }"
                                        @sort-change="sortChange"
                                        @selection-change="
                                            handleSelectionChange
                                        "
                                    >
                                        <el-table-column
                                            type="selection"
                                            width="55"
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="name"
                                            label="发票"
                                            width="320"
                                        >
                                            <template slot-scope="scope">
                                                <div
                                                    :style="{
                                                        backgroundColor:
                                                            scope.row
                                                                .iconDominantColor ||
                                                            '#ebad41',
                                                    }"
                                                    class="fapiao-detail"
                                                >
                                                    <div
                                                        class="fapiao-detail-logo"
                                                    >
                                                        <img
                                                            height="42px"
                                                            width="42px"
                                                            :src="
                                                                scope.row.icon
                                                            "
                                                            alt=""
                                                            srcset=""
                                                        />
                                                    </div>
                                                    <div
                                                        class="fapiao-detail-provider"
                                                    >
                                                        <div
                                                            class="provider-company-name"
                                                        >
                                                            {{
                                                                scope.row
                                                                    .payeeShortName
                                                            }}
                                                        </div>
                                                        <div
                                                            class="fapiao-folder"
                                                        >
                                                            {{ scope.row.type }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="fapiao-detail-master"
                                                    >
                                                        <div
                                                            class="fapiao-amount"
                                                        >
                                                            ￥{{
                                                                scope.row.amount
                                                            }}
                                                        </div>
                                                        <div
                                                            class="mastyer-company-name"
                                                        >
                                                            {{
                                                                scope.row
                                                                    .payerShortName
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="invoiceType"
                                            label="发票类型"
                                            show-overflow-tooltip
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="sourceName"
                                            label="发票来源"
                                            show-overflow-tooltip
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            prop="importTime"
                                            label="入库时间"
                                            sortable
                                        >
                                        </el-table-column>
                                        <el-table-column
                                            label="操作"
                                            width="100"
                                        >
                                            <template slot-scope="scope">
                                                <el-button
                                                    @click.native.prevent="
                                                        switchEmailVisible(
                                                            'detail',
                                                            tableData[
                                                                scope.$index
                                                            ]
                                                        )
                                                    "
                                                    type="text"
                                                    size="small"
                                                >
                                                    查看
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>

                                <div class="table-box-footer">
                                    <div class="footer-operation">
                                        <!-- <el-checkbox
                                            :indeterminate="isIndeterminate"
                                            v-model="currentPage"
                                            @change="selectCurrentPage"
                                            >选择本页</el-checkbox
                                        > -->
                                        <el-checkbox
                                            :indeterminate="isIndeterminate"
                                            v-model="allPage"
                                            @change="selectAllPage"
                                            >选择全部</el-checkbox
                                        >
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            :disabled="
                                                multipleSelection.length == 0
                                            "
                                            class="export-fapiao"
                                            @click="openInputEmail"
                                            >导出选中</el-button
                                        >
                                    </div>
                                    <el-pagination
                                        background
                                        layout="total, prev, pager, next"
                                        :total="total"
                                        :page-size="pageSize"
                                        @current-change="handleCurrentChange"
                                        :current-page="currentPage"
                                    >
                                    </el-pagination>
                                </div>
                            </div>
                        </el-tab-pane>
                        <!-- <el-tab-pane>
                            <span slot="label">处理记录（888）</span>
                        </el-tab-pane> -->
                    </el-tabs>
                </div>
            </el-main>
        </el-container>
        <el-dialog
            title="请输入邮箱"
            :visible.sync="showInputEmailVisible"
            width="40%"
        >
            <div class="email-input-content">
                <div
                    style="color: red; font-size: 12px; margin-bottom: 8px"
                    class="email-input-content-tips"
                >
                    多个邮箱之间用分号分割
                </div>
                <div class="email-input-content-input">
                    <el-input
                        size="small"
                        v-model="emailListValue"
                        placeholder="请输入邮箱"
                    ></el-input>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeInputEmail"
                    >取 消</el-button
                >
                <el-button size="small" type="primary" @click="submitInputEmail"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <MonitorDetail
            @switchEmailVisible="switchEmailVisible"
            :invoiceInfo="invoiceInfo"
            v-show="visibleType != 'list'"
        ></MonitorDetail>
    </el-container>
</template>

<script>
import LeftMenu from "./../../components/menu/leftMenu.vue";
import MonitorDetail from "./MonitorDetail.vue";

export default {
    components: {
        LeftMenu,
        MonitorDetail,
    },
    data() {
        return {
            tabsValue: "exception",
            remoteLoading: false,
            total: 0,
            isIndeterminate: false,
            currentPage: 1,
            allPage: false,
            selectFapiaoType: "-1",
            invoiceSource: [],
            sourceConfiArr: [],
            sourceOptions: [],
            importTimeRange: [],
            createTimeRange: [],
            qwValue: [],
            qwOptions: [],
            acountRangeType: "1",
            isShowDropdownBox: false,
            fapiaoAmountStart: "",
            fapiaoAmountEnd: "",
            invoiceTypeOptions: [],
            invoiceType: [],
            invoiceTypeConfiArr: [],
            recoverableNameOptions: [],
            recoverableName: [],
            buyerNameOptions: [],
            buyerName: [],
            exceptionCount: "",
            totalCount: "",
            //
            showInputEmailVisible: false,
            emailListValue: "",

            tableData: [],
            multipleSelection: [],
            visibleType: "list", // list detail
            emailInfo: {},

            pageSize: 20,
            orderType: "descending",
            invoiceInfo: {},

            // 筛选条件展示
            conditionSelectFapiaoType: "-1",
            conditionInvoiceSource: [],
            conditionSourceConfiArr: [],
            conditionInvoiceTypeConfiArr: [],
            conditionImportTimeRange: [],
            conditionQwValue: [],
            conditionAcountRangeType: "1",
            conditionFapiaoAmountStart: "",
            conditionFapiaoAmountEnd: "",
            conditionInvoiceType: [],
            conditionRecoverableName: [],
            conditionBuyerName: [],
            conditionCreateTimeRange: [],
        };
    },

    methods: {
        // 条件编辑
        closeCondition(type) {
            switch (type) {
                case "selectFapiaoType":
                    this.conditionSelectFapiaoType = "-1";
                    break;
                case "invoiceSource":
                    this.conditionInvoiceSource = [];
                    this.conditionSourceConfiArr = [];
                    break;
                case "importTimeRange":
                    this.conditionImportTimeRange = [];
                    break;
                case "qwValue":
                    this.conditionQwValue = [];
                    break;
                case "acountRangeType":
                    this.conditionAcountRangeType = "1";
                    this.conditionFapiaoAmountStart = "";
                    this.conditionFapiaoAmountEnd = "";
                    break;
                case "createTimeRange":
                    this.conditionCreateTimeRange = [];
                    break;
                case "invoiceType":
                    // this.conditionInvoiceType = [];
                    // this.invoiceTypeConfiArr = [];
                    this.conditionInvoiceTypeConfiArr = [];
                    break;
                case "recoverableName":
                    this.conditionRecoverableName = [];
                    break;
                case "buyerName":
                    this.conditionBuyerName = [];
                    break;
                default:
                    break;
            }
            this.getInvoiceList();
        },
        // 条件展示
        invoiceSourceChange() {
            this.conditionSourceConfiArr = [];
            this.invoiceSource.forEach((item) => {
                this.sourceOptions.forEach((cell) => {
                    if (item == cell.code) {
                        this.conditionSourceConfiArr.push(cell.name);
                    }
                });
            });
        },
        // invoiceTypeConfiArr
        invoiceTypeChange() {
            this.conditionInvoiceTypeConfiArr = [...this.invoiceType];
        },
        //importTimeRange 入库时间展示
        importTimeRangeConfi(importTimeRange) {
            let start = new Date(importTimeRange[0]).getTime();
            let end = new Date(importTimeRange[1]).getTime();
            let str = "";
            if (start == end) {
                str = this.changeDate(start, "date");
            } else {
                str =
                    this.changeDate(start, "date") +
                    "~" +
                    this.changeDate(end, "date");
            }
            return str;
        },
        // 问题类型展示
        qwValueConfi(conditionQwValue) {
            let tampArr = [];
            conditionQwValue.forEach((item) => {
                this.qwOptions.forEach((cell) => {
                    if (item == cell.code) {
                        tampArr.push(cell.name);
                    }
                });
            });
            return tampArr.join("、");
        },
        changeDate(datetimes, type) {
            let date = new Date(datetimes);
            let Y = date.getFullYear() + "-";
            let M =
                (date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1) + "-";
            let D =
                (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
                " ";
            let h =
                (date.getHours() > 9
                    ? date.getHours()
                    : "0" + date.getHours()) + ":";
            let m =
                (date.getMinutes() > 9
                    ? date.getMinutes()
                    : "0" + date.getMinutes()) + ":";
            let s =
                date.getSeconds() > 9
                    ? date.getSeconds()
                    : "0" + date.getSeconds();
            //      年 月 日 时 分 秒
            if (type == "time") {
                return Y + M + D + h + m + s;
            } else {
                return Y + M + D;
            }
        },
        // 排序修改
        sortChange(obj) {
            this.orderType = obj.order;
            this.getInvoiceList();
        },
        // 切换tab发票类型
        switchTab() {
            this.allPage = false;
            this.currentPage = 1;
            this.multipleSelection = [];
            this.resetCondition();
            this.getInvoiceList();
        },
        // 展示list or detail
        switchEmailVisible(value, obj) {
            console.log(value);
            this.visibleType = value.type;
            this.emailInfo = obj;
            obj && this.getDetail(obj?.id, value);
            if(this.visibleType === 'list') {
              // 高级搜索
              this.getInvoiceSources();
              this.getExceptionTypes();
              this.getInvoicGroups();
              // 发票列表
              this.getInvoiceList();
              // 发票数
              this.getCount();
            }
        },
        // 获取detail
        getDetail(id, value) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.FetchGet(this.requestUrl.invoice.detail + id, {})
                .then((res) => {
                    loading.close();
                    if (res.errno === "0") {
                        this.invoiceInfo = {...res.data, statusBtn: value};
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    loading.close();
                    this.$message(err);
                });
        },
        // 高级筛选
        handleSearch(type) {
            if (type == "reset") {
                this.resetCondition();
                this.resetHandleSearch();
                this.getInvoiceList();
            }
            if (type == "close") {
                this.isShowDropdownBox = false;
            }
            if (type == "submit") {
                this.isShowDropdownBox = false;
                this.conditionSelectFapiaoType = this.selectFapiaoType;
                this.conditionInvoiceSource = this.invoiceSource;
                this.invoiceSourceChange();
                this.conditionInvoiceTypeConfiArr = this.invoiceType;
                this.conditionImportTimeRange = this.importTimeRange;
                this.conditionQwValue = [...this.qwValue];
                this.conditionAcountRangeType = this.acountRangeType;
                this.conditionFapiaoAmountStart = this.fapiaoAmountStart;
                this.conditionFapiaoAmountEnd = this.fapiaoAmountEnd;
                this.invoiceTypeChange();
                this.conditionRecoverableName = this.recoverableName;
                this.conditionBuyerName = this.buyerName;
                this.conditionCreateTimeRange = this.createTimeRange;
                this.getInvoiceList();
            }
        },
        // 分页操作
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.getInvoiceList();
        },
        // 获取列表数据
        getInvoiceList() {
            let paramObj = {
                invType: this.conditionSelectFapiaoType,
                startAmount: this.conditionFapiaoAmountStart,
                endAmount: this.conditionFapiaoAmountEnd,
                invGroup: this.conditionInvoiceTypeConfiArr,
                importEndTime: new Date(
                    this.conditionImportTimeRange[1]
                ).getTime(),
                importStartTime: new Date(
                    this.conditionImportTimeRange[0]
                ).getTime(),
                invEndTime: new Date(
                    this.conditionCreateTimeRange[1]
                ).getTime(),
                invStartTime: new Date(
                    this.conditionCreateTimeRange[0]
                ).getTime(),
                exceptionType: this.conditionQwValue,
                orderBy: "createTime",
                orderType: this.orderType == "ascending" ? 0 : 1,
                pageNo: this.currentPage,
                pageSize: 20,
                payee: this.conditionRecoverableName,
                payer: this.conditionBuyerName,
                source: this.conditionInvoiceSource,
            };
            if (paramObj.importEndTime) {
                paramObj.importEndTime =
                    paramObj.importEndTime + (24 * 60 * 60 * 1000 - 1);
            }
            if (paramObj.invEndTime) {
                paramObj.invEndTime = paramObj.invEndTime + (24 * 60 * 60 * 1000 - 1);
            }
            Object.keys(paramObj).forEach((item) => {
                if (!paramObj[item] || paramObj[item].length == 0)
                    delete paramObj[item];
            });
            let url = "";
            if (this.tabsValue == "exception") {
                url = this.requestUrl.invoice.invoiceException;
            }
            if (this.tabsValue == "all") {
                url = this.requestUrl.invoice.invoiceAll;
            }
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            setTimeout(() => {
                loading.close();
            }, 500)
            this.FetchPost(url, paramObj)
                .then((res) => {
                    loading.close();
                    if (res.errno == "0") {
                        this.tableData = res.data.list;
                        this.total = res.data.total;
                        if (this.allPage) {
                            this.$nextTick(() => {
                                this.tableData.forEach(() => {
                                    if (this.tabsValue == "all") {
                                        this.$refs.multipleTableA.toggleAllSelection();
                                    } else {
                                        this.$refs.multipleTableE.toggleAllSelection();
                                    }
                                });
                            });
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    loading.close();
                    this.$message(err);
                });
        },
        // 重置高级筛选输入
        resetHandleSearch() {
            this.selectFapiaoType = "-1";
            this.invoiceSource = [];
            this.sourceConfiArr = [];
            this.invoiceTypeConfiArr = [];
            this.importTimeRange = [];
            this.qwValue = [];
            this.acountRangeType = "1";
            this.fapiaoAmountStart = "";
            this.fapiaoAmountEnd = "";
            this.invoiceType = [];
            this.recoverableName = [];
            this.buyerName = [];
            this.createTimeRange = [];
        },
        // 清空展示筛选条件
        resetCondition() {
            this.conditionSelectFapiaoType = "-1";
            this.conditionInvoiceSource = [];
            this.conditionSourceConfiArr = [];
            this.conditionInvoiceTypeConfiArr = [];
            this.conditionImportTimeRange = [];
            this.conditionQwValue = [];
            this.conditionAcountRangeType = "1";
            this.conditionFapiaoAmountStart = "";
            this.conditionFapiaoAmountEnd = "";
            this.conditionInvoiceType = [];
            this.conditionRecoverableName = [];
            this.conditionBuyerName = [];
            this.conditionCreateTimeRange = [];
        },
        // 导出选中 邮箱填写
        openInputEmail() {
            this.showInputEmailVisible = true;
        },
        // 关闭 导出 邮箱输入
        closeInputEmail() {
            this.emailListValue = "";
            this.showInputEmailVisible = false;
        },
        // 提交 导出邮箱
        submitInputEmail() {
            if (this.emailListValue.trim() == "") {
                this.$message({
                    message: "请输入邮箱地址",
                    type: "warning",
                });
                return false;
            }
            let flag = true;
            this.emailListValue
                .replace(/;/g, "；")
                .split("；")
                .forEach((item) => {
                    if (!this.IsEmail(item)) {
                        flag = false;
                        this.$message({
                            message: "请输入正确的邮箱地址",
                            type: "warning",
                        });
                    }
                });

            if (flag)
                this.exportInvoice(
                    this.emailListValue.replace(/;/g, "；").split("；")
                );
        },
        // 获取导出ids
        toolGetMultipleSelectionIds() {
            let arr = [];
            this.multipleSelection.forEach((item) => {
                arr.push(item.id);
            });
            return arr;
        },
        // 导出到邮箱
        exportInvoice(emails) {
            let exportType = this.tabsValue == "all" ? 0 : 1;
            let param = {
                emails: emails,
                exportAll: this.allPage,
                exportType: exportType,
            };
            if (this.allPage) {
                let paramObj = {
                    startAmount: this.fapiaoAmountStart,
                    endAmount: this.fapiaoAmountEnd,
                    invGroup: this.invoiceType,
                    importEndTime: this.importTimeRange[1],
                    importStartTime: this.importTimeRange[0],
                    invEndTime: this.createTimeRange[1],
                    invStartTime: this.createTimeRange[0],
                    exceptionType: this.qwValue,
                    payee: this.recoverableName,
                    payer: this.buyerName,
                    source: this.invoiceSource,
                };
                Object.keys(paramObj).forEach((item) => {
                    if (!paramObj[item] || paramObj[item].length == 0)
                        delete paramObj[item];
                });
                param.invoiceFilter = paramObj;
            } else {
                param.invoiceIds = this.toolGetMultipleSelectionIds();
            }
            this.FetchPost(this.requestUrl.invoice.export, param)
                .then((res) => {
                    if (res.errno === "0") {
                        this.showInputEmailVisible = false;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 校验邮箱地址
        IsEmail(str) {
            let reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
            return reg.test(str);
        },
        // 选择发票
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        selectCurrentPage() {},
        // 选择所有发票
        selectAllPage() {
            this.$nextTick(() => {
                this.tableData.forEach(() => {
                    if (this.tabsValue == "all") {
                        this.$refs.multipleTableA.toggleAllSelection();
                    } else {
                        this.$refs.multipleTableE.toggleAllSelection();
                    }
                });
            });
        },
        switchDropdown() {
            this.isShowDropdownBox = !this.isShowDropdownBox;
            if (this.isShowDropdownBox) {
                this.selectFapiaoType = this.conditionSelectFapiaoType;
                this.invoiceSource = this.conditionInvoiceSource;
                this.sourceConfiArr = this.conditionSourceConfiArr;
                this.invoiceTypeConfiArr = this.conditionInvoiceTypeConfiArr;
                this.invoiceType = this.conditionInvoiceTypeConfiArr;
                this.importTimeRange = this.conditionImportTimeRange;
                this.qwValue = this.conditionQwValue;
                this.acountRangeType = this.conditionAcountRangeType;
                this.fapiaoAmountStart = this.conditionFapiaoAmountStart;
                this.fapiaoAmountEnd = this.conditionFapiaoAmountEnd;
                // this.invoiceType = this.conditionInvoiceType;
                this.recoverableName = this.conditionRecoverableName;
                this.buyerName = this.conditionBuyerName;
                this.createTimeRange = this.conditionCreateTimeRange;
            }
        },
        // 获取所有的发票来源
        getInvoiceSources() {
            this.FetchGet(this.requestUrl.invoice.sources, {})
                .then((res) => {
                    if (res.errno === "0") {
                        this.sourceOptions = [...res.data];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取所有的问题类型
        getExceptionTypes() {
            this.FetchGet(this.requestUrl.invoice.exceptionTypes, {})
                .then((res) => {
                    if (res.errno === "0") {
                        this.qwOptions = [...res.data];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取所有的问题类型
        getInvoicGroups() {
            this.FetchGet(this.requestUrl.invoice.groups, {})
                .then((res) => {
                    if (res.errno === "0") {
                        this.invoiceTypeOptions = [...res.data];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 买方销方远程搜索
        remoteMethod(query) {
            if (query !== "") {
                this.remoteLoading = true;
                this.FetchGet(
                    this.requestUrl.invoice.company + "?keyword=" + query,
                    {}
                )
                    .then((res) => {
                        if (res.errno === "0") {
                            this.remoteLoading = false;
                            this.recoverableNameOptions = res.data;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            } else {
                this.recoverableNameOptions = [];
            }
        },
        // 获取发票数量，异常及所有
        getCount() {
            this.FetchGet(this.requestUrl.invoice.count, {})
                .then((res) => {
                    if (res.errno === "0") {
                        this.exceptionCount = res.data.exceptionCount;
                        this.totalCount = res.data.totalCount;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
    },
    mounted() {
        // 高级搜索
        this.getInvoiceSources();
        this.getExceptionTypes();
        this.getInvoicGroups();
        // 发票列表
        this.getInvoiceList();
        // 发票数
        this.getCount();
    },
};
</script>

<style lang="less">
.el-select--small {
    width: 100% !important;
    overflow: hidden;
}
.el-tabs__item {
    text-align: left !important;
}
.el-table--mini td,
.el-table--mini th {
    padding: 0 0 !important;
}
.el-table__fixed-right-patch {
    height: 0 !important;
}
@import "./../../assets/less/monitor/monitor.less";
</style>
