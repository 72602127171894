<template>
    <el-container>
        <div id="login-box">
            <!-- <div class="login-box-logo">
        <img src="./../../assets/images/logo.png" alt="" class="logo">
      </div> -->
            <div class="login-box-title">爱发票运营系统</div>
            <div class="login-box-form">
                <el-form
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    ref="ruleForm"
                    class="demo-ruleForm"
                >
                    <el-form-item label="账号" prop="account">
                        <el-input  placeholder="请输入账号" v-model.number="ruleForm.account"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="pass">
                        <el-input
                            type="password"
                            v-model="ruleForm.pass"
                            placeholder="请输入密码"
                            @keyup.enter.native="submitForm('ruleForm')"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            class="login-button"
                            type="primary"
                            @click="submitForm('ruleForm')"
                            >登 录</el-button
                        >
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-container>
</template>

<script>
export default {
    data() {
        var checkAccount = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("账号不能为空"));
            } else {
                callback();
            }
        };
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                pass: "",
                account: "",
            },
            rules: {
                pass: [
                    {
                        validator: validatePass,
                        trigger: "blur",
                    },
                ],
                account: [
                    {
                        validator: checkAccount,
                        trigger: "blur",
                    },
                ],
            },
            labelPosition: top,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.FetchPost(
                        this.requestUrl.auth.login +
                            "?userName=" + this.ruleForm.account + "&password=" + this.ruleForm.pass,
                        {},
                        { emulateJSON: true }
                    ).then((res) => {
                        debugger
                        if (res.errno === "0") {
                            location.hash = "/home";
                        } else {
                            this.$message.error(res.errmsg);
                        }
                    });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less">
@import "./../../assets/less/login/login.less";
</style>
